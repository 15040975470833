.psLogo062Icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
  object-fit: cover;
}
.frameDiv1 {
  width: 43px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--beige-10);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-20xs) var(--padding-17xs);
  background-color: var(--brown-100);
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Div {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  cursor: pointer;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  align-items: center;
}
.frameDiv {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  width: 1920px;
  flex-direction: column;
  padding: var(--padding-13xs) var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}
.group9361 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1052px;
  object-fit: cover;
}
.formTitleDiv,
.formTitleSubtext {
  align-self: stretch;
  position: relative;
  color: var(--grey-100);
  text-align: center;
  display: inline-block;
}
.formTitleSubtext {
  font-size: var(--body-text-size);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
}
.formTitleDiv {
  font-size: var(--mid-tittles-size);
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
  font-family: var(--h1);
}
.formHeaderDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-14xs);
}
.inputTextField {
  border: 0;
  background-color: transparent;
  align-self: stretch;
}
.destinationNameInput,
.formFieldsDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.formFieldsDiv {
  gap: var(--gap-13xs);
}
.buttonDiv {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 7.46px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--studio-darkmode-allwhite-ffffff);
  text-align: left;
  display: inline-block;
}
.unstyledButtonDiv {
  overflow: hidden;
  padding: var(--padding-21xs) var(--padding-15xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.button1,
.contactForm,
.formDiv,
.unstyledButtonDiv {
  display: flex;
  flex-direction: column;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--brown-100);
  align-self: stretch;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.button1:hover {
  background-color: #8d8172;
}
.contactForm,
.formDiv {
  box-sizing: border-box;
}
.formDiv {
  align-self: stretch;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  padding: 28px var(--padding-12xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17px;
}
.contactForm {
  position: relative !important;
  top: 185px;
  left: auto !important;
  background-color: #e2e0de;
  width: 680px;
  padding: var(--padding-2xs) var(--padding-17xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
  margin-left: 600px;
  margin-right: auto;
}
.contactUsDiv2 {
  align-self: stretch;
  position: relative;
  height: 1052px;
  flex-shrink: 0;
  overflow: hidden;
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.logoDiv,
.socialIcons {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.socialIcons {
  width: 96px;
  height: 24px;
}
.logoAndSocialMedia {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-4xs) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.homeDiv1 {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.09em;
  line-height: 148%;
  display: inline-block;
  width: 134px;
}
.contactUsDiv3,
.ourApproachDiv,
.ourCompetitiveAvantage,
.portfolioStrategiesDiv,
.whyPSDiv {
  position: absolute;
  top: 33px;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 134px;
}
.contactUsDiv3,
.ourApproachDiv,
.ourCompetitiveAvantage,
.whyPSDiv {
  top: 66px;
}
.contactUsDiv3,
.ourApproachDiv,
.ourCompetitiveAvantage {
  top: 99px;
}
.contactUsDiv3,
.ourApproachDiv {
  top: 132px;
}
.contactUsDiv3 {
  top: 165px;
}
.homeDiv {
  position: relative;
  width: 134px;
  height: 183px;
  flex-shrink: 0;
  cursor: pointer;
}
.aboutUsDiv1,
.historyDiv,
.ourTeamDiv,
.overviewDiv,
.valuesDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 94px;
}
.aboutUsDiv1 {
  top: 0;
  letter-spacing: 0.09em;
}
.historyDiv,
.ourTeamDiv,
.overviewDiv,
.valuesDiv {
  top: 33px;
}
.historyDiv,
.ourTeamDiv,
.valuesDiv {
  top: 66px;
}
.ourTeamDiv,
.valuesDiv {
  top: 99px;
}
.ourTeamDiv {
  top: 132px;
}
.aboutUsDiv {
  position: relative;
  width: 94px;
  height: 150px;
  flex-shrink: 0;
  cursor: pointer;
}
.fAQsDiv,
.ourProcessDiv1,
.overviewDiv1,
.processDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 78px;
}
.ourProcessDiv1 {
  top: 0;
  letter-spacing: 0.09em;
}
.fAQsDiv,
.overviewDiv1,
.processDiv {
  top: 33px;
}
.fAQsDiv,
.processDiv {
  top: 66px;
}
.fAQsDiv {
  top: 99px;
}
.ourProcessDiv {
  position: relative;
  width: 78px;
  height: 117px;
  flex-shrink: 0;
  cursor: pointer;
}
.contactUsDiv5,
.contactUsDiv6,
.mapDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 72px;
  cursor: pointer;
}
.contactUsDiv5 {
  top: 0;
  letter-spacing: 0.09em;
}
.contactUsDiv6,
.mapDiv {
  top: 33px;
}
.contactUsDiv6 {
  top: 66px;
}
.contactUsDiv4 {
  position: relative;
  width: 72px;
  height: 84px;
  flex-shrink: 0;
}
.footerLinks,
.topLinksDiv {
  display: flex;
  flex-direction: row;
}
.footerLinks {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.topLinksDiv {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.pORTFOLIOSTRATEGIESAllRigh,
.privacyPolicyTermsAndCon {
  position: relative;
  line-height: 148%;
  display: inline-block;
  width: 287px;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon {
  width: 192px;
  height: 19px;
}
.madeWithSpan {
  color: var(--studio-darkmode-allwhite-ffffff);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  position: relative;
  display: inline-block;
  width: 206px;
  height: 19px;
  flex-shrink: 0;
}
.policiesDiv {
  width: 1223.34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footerDiv,
.footerSectionDiv {
  align-self: stretch;
  align-items: flex-start;
}
.footerDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-md);
}
.footerSectionDiv {
  background-color: var(--color-gray-1800);
  padding: var(--padding-8xs) var(--padding-lg);
  box-sizing: border-box;
}
.contactUsDiv,
.contactUsDiv1,
.footerSectionDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contactUsDiv1 {
  align-self: stretch;
  align-items: flex-end;
  gap: 1px;
}
.contactUsDiv {
  position: relative;
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: var(--font-size-5xs);
  color: var(--studio-darkmode-allwhite-ffffff);
  font-family: var(--header-tittles);
}
@media screen and (max-width: 1200px) {
  .button {
    background-color: var(--brown-100);
  }
  .contactForm {
    margin-left: auto;
    margin-right: auto;
    position: relative !important;
    left: auto !important;
  }
  .policiesDiv {
    width: 1080px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .contactUsDiv1 {
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .frameDiv2 {
    gap: 30;
  }
  .navBarDiv {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .button1 {
    background-color: var(--color-tan);
  }
  .contactForm {
    padding: var(--padding-14xs) 0;
    box-sizing: border-box;
    margin-left: 37px;
    margin-right: auto;
    position: relative !important;
    left: auto !important;
    max-width: calc(100vw - 80px);
    width: 100%;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-20xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .policiesDiv,
  .topLinksDiv {
    flex-direction: row;
  }
  .policiesDiv {
    width: 690px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-20xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .button1 {
    background-color: var(--brown-100);
    width: 100%;
  }
  .contactForm {
    width: 100%;
    padding-left: var(--padding-14xs);
    padding-right: var(--padding-14xs);
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    position: relative !important;
    left: auto !important;
  }
  .formDiv {
    padding: 0;
  }
  .portfolioStrategiesDiv,
  .whyPSDiv {
    display: none;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
  }
  .privacyPolicyTermsAndCon {
    width: 192px;
  }
  .madeWithByDigitalArtist {
    width: 261px;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }

}
