@keyframes shadow-drop-bottom {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  to {
    box-shadow: 0 12px 20px -12px rgb(0 0 0/35%);
  }
}
.psLogo062Icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
  object-fit: cover;
}
.frameDiv1 {
  width: 43px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--beige-10);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-20xs) var(--padding-17xs);
  background-color: var(--brown-100);
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Div {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  cursor: pointer;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  align-items: center;
}
.frameDiv {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  width: 1920px;
  flex-direction: column;
  padding: var(--padding-13xs) var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}
.resourcedDirectEfficientDiv {
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.6;
}
.ourProcessDiv1,
.resourcedDirectEfficientDiv,
.weMakeItSimpleToBeginEar {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.ourProcessDiv1 {
  font-size: var(--h1-size);
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  font-family: var(--h1);
}
.weMakeItSimpleToBeginEar {
  font-size: var(--body-text-size);
  line-height: 148%;
}
.frameDiv4,
.ourProcessDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.ourProcessDiv {
  background-color: var(--light-10);
  padding: var(--padding-md) var(--padding-14xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--grey-100);
  font-family: var(--header-tittles);
}
.danielleCerulloBIZJRVBLfOMUIcon {
  flex: 1;
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frameDiv5 {
  flex: 1;
  height: 387px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.div2 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  display: inline-block;
  border: 1px solid #917E73;
  border-radius: 20px;
  padding: 10px;
}
.div1 {
  border-radius: var(--br-md);
  width: 27px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-19xs) var(--padding-20xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.bodyItIsOurMissionToBu,
.initialInquiryDiv {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.initialInquiryDiv {
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
}
.bodyItIsOurMissionToBu {
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
}
.frameDiv6,
.frameDiv7,
.text1 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.text1 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xs);
  font-size: var(--mid-tittles-size);
  color: var(--grey-100);
}
.frameDiv6,
.frameDiv7 {
  flex-direction: row;
}
.frameDiv7 {
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.frameDiv6 {
  align-items: center;
}
.ellipseIcon4,
.rectangleDiv {
  position: relative;
  flex-shrink: 0;
}
.rectangleDiv {
  border-radius: var(--br-md);
  background-color: var(--brown-60);
  width: 8px;
  height: 22px;
}
.ellipseIcon4 {
  width: 7px;
  height: 7px;
  opacity: 0.3;
  cursor: pointer;
}
.frameDiv8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.div,
.ourProcessDiv2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.div {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.ourProcessDiv2 {
  background-color: var(--light-10);
  flex-direction: column;
  padding: var(--padding-9xs) var(--padding-4xs);
  box-sizing: border-box;
  justify-content: center;
}
.frameDiv10,
.frameDiv12,
.frameDiv9 {
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
}
.frameDiv9 {
  height: 387px;
  display: none;
  align-items: flex-start;
}
.frameDiv10,
.frameDiv12 {
  display: flex;
  align-items: center;
}
.frameDiv12 {
  height: 387px;
  align-items: center;
}
.planDevelopmentImplementat {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
  display: inline-block;
}
.frameDiv19,
.frameDiv21,
.text12 {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.text12 {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xs);
  font-size: var(--mid-tittles-size);
  color: var(--grey-100);
}
.frameDiv19,
.frameDiv21 {
  flex-direction: row;
  align-items: center;
}
.frameDiv21 {
  height: 387px;
  align-items: center;
}
.bodyItIsOurMissionToBu4 {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 0.21em;
  line-height: 148%;
  text-transform: uppercase;
  font-weight: 500;
  font-family: var(--header-tittles);
  color: var(--light-10);
  text-align: center;
  display: inline-block;
}
.frameButton,
.ourProcessDiv6 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.frameButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-18xs) var(--padding-12xs);
  background-color: var(--color-gray-1300);
  border-radius: var(--br-xs);
  flex-direction: row;
  justify-content: flex-start;
}
.frameButton1:hover,
.frameButton:hover {
  animation: 1s ease 0s infinite normal none shadow-drop-bottom;
  opacity: 1;
  background-color: var(--color-gray-1000);
}
.ourProcessDiv6 {
  align-self: stretch;
  background-color: var(--light-10);
  flex-direction: column;
  padding: var(--padding-9xs) var(--padding-4xs);
  justify-content: center;
}
.bodyItIsOurMissionToBu5,
.frequentlyAskedQuestions {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.frequentlyAskedQuestions {
  align-self: stretch;
  letter-spacing: 0.02em;
  line-height: 116%;
}
.bodyItIsOurMissionToBu5 {
  font-size: var(--font-size-xs);
  letter-spacing: 0.21em;
  line-height: 148%;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  text-align: center;
  color: var(--brown-10);
}
.frameButton1,
.frameDiv25 {
  display: flex;
  justify-content: center;
}
.frameButton1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-18xs) var(--padding-12xs);
  background-color: var(--color-gray-1300);
  border-radius: var(--br-xs);
  width: 590px;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}
.frameButton1:hover {
  background-color: var(--color-gray-800);
}
.frameDiv25 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-2xs);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  height: 2px;
  flex-shrink: 0;
  opacity: 0.6;
  background: #3F515F;
}
.whatIsTheMinimumAmountOf {
  flex: 1;
  position: relative;
  line-height: 25px;
  font-weight: 500;
  display: inline-block;
  font-weight: bold;
}

.moreQuestion {
  padding: 10px 20px;
  max-width: 550px;
  font-size: 18px;
  line-height: 148%;
}

.icon {
  position: relative;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
}
.frameDiv27 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}
.frameDiv27,
.frameDiv28,
.frameDiv29 {
  display: flex;
  justify-content: flex-start;
  padding: 15px 20px 0;
}
.frameDiv28 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-13xl);
}
.frameDiv29 {
  gap: var(--gap-14xl);
}
.frameDiv29,
.frameDiv30,
.frameDiv31 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}
.frameDiv30 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  padding: 15px 20px 0;
}
.frameDiv31 {
  gap: var(--gap-8xl);
  padding: 15px 20px 0;

}
.frameDiv26,
.frameDiv31,
.frameDiv32 {
  display: flex;
  justify-content: flex-start;
}
.frameDiv32 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-15xl);
  padding: 15px 20px 0;
}
.frameDiv26 {
  flex: 1;
  min-height: 382px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-13xs);
  font-size: var(--font-size-lg);
  font-family: var(--header-tittles);
}
.frameDiv23,
.frameDiv24 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frameDiv24 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.frameDiv23 {
  background-color: var(--studio-darkmode-allwhite-ffffff);
  min-height: 750px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
  font-size: var(--h1-size);
  color: var(--grey-100);
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.logoDiv,
.socialIcons {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.socialIcons {
  width: 96px;
  height: 24px;
}
.logoAndSocialMedia {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-4xs) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.homeDiv1 {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.09em;
  line-height: 148%;
  display: inline-block;
  width: 134px;
}
.contactUsDiv,
.ourApproachDiv,
.ourCompetitiveAvantage,
.portfolioStrategiesDiv,
.whyPSDiv {
  position: absolute;
  top: 33px;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 134px;
}
.contactUsDiv,
.ourApproachDiv,
.ourCompetitiveAvantage,
.whyPSDiv {
  top: 66px;
}
.contactUsDiv,
.ourApproachDiv,
.ourCompetitiveAvantage {
  top: 99px;
}
.contactUsDiv,
.ourApproachDiv {
  top: 132px;
}
.contactUsDiv {
  top: 165px;
}
.homeDiv {
  position: relative;
  width: 134px;
  height: 183px;
  flex-shrink: 0;
  cursor: pointer;
}
.aboutUsDiv1,
.historyDiv,
.ourTeamDiv,
.overviewDiv,
.valuesDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 94px;
}
.aboutUsDiv1 {
  top: 0;
  letter-spacing: 0.09em;
}
.historyDiv,
.ourTeamDiv,
.overviewDiv,
.valuesDiv {
  top: 33px;
}
.historyDiv,
.ourTeamDiv,
.valuesDiv {
  top: 66px;
}
.ourTeamDiv,
.valuesDiv {
  top: 99px;
}
.ourTeamDiv {
  top: 132px;
}
.aboutUsDiv {
  position: relative;
  width: 94px;
  height: 150px;
  flex-shrink: 0;
  cursor: pointer;
}
.fAQsDiv,
.ourProcessDiv8,
.overviewDiv1,
.processDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 78px;
  cursor: pointer;
}
.ourProcessDiv8 {
  top: 0;
  letter-spacing: 0.09em;
}
.fAQsDiv,
.overviewDiv1,
.processDiv {
  top: 33px;
}
.fAQsDiv,
.processDiv {
  top: 66px;
}
.fAQsDiv {
  top: 99px;
}
.ourProcessDiv7 {
  position: relative;
  width: 78px;
  height: 117px;
  flex-shrink: 0;
}
.contactUsDiv2,
.contactUsDiv3,
.mapDiv {
  position: absolute;
  left: 0;
  line-height: 148%;
  display: inline-block;
  width: 72px;
}
.contactUsDiv2 {
  top: 0;
  letter-spacing: 0.09em;
}
.contactUsDiv3,
.mapDiv {
  top: 33px;
}
.contactUsDiv3 {
  top: 66px;
}
.contactUsDiv1 {
  position: relative;
  width: 72px;
  height: 84px;
  flex-shrink: 0;
  cursor: pointer;
}
.footerLinks,
.topLinksDiv {
  display: flex;
  flex-direction: row;
}
.footerLinks {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
}
.topLinksDiv {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.lineDiv6 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.pORTFOLIOSTRATEGIESAllRigh,
.privacyPolicyTermsAndCon {
  position: relative;
  line-height: 148%;
  display: inline-block;
  width: 287px;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon {
  width: 192px;
  height: 19px;
}
.madeWithSpan {
  color: var(--studio-darkmode-allwhite-ffffff);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  position: relative;
  display: inline-block;
  width: 206px;
  height: 19px;
  flex-shrink: 0;
}
.policiesDiv {
  width: 1223.34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footerDiv {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-md);
}
.footerDiv,
.footerSectionDiv,
.ourProcessPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footerSectionDiv {
  align-self: stretch;
  background-color: var(--color-gray-1800);
  padding: var(--padding-8xs) var(--padding-lg);
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--font-size-5xs);
  color: var(--studio-darkmode-allwhite-ffffff);
  font-family: var(--header-tittles);
}
.ourProcessPage {
  position: relative;
  width: 100%;
  align-items: flex-end;
  text-align: left;
  font-size: var(--body-text-size);
  color: var(--brown-60);
  font-family: var(--h1);
}
@media screen and (max-width: 1200px) {
  .button {
    background-color: var(--brown-100);
  }
  .ourProcessDiv {
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-12xl);
    box-sizing: border-box;
  }
  .frameDiv10 {
    width: 400px;
    padding-left: 0;
    box-sizing: border-box;
  }
  .frameDiv12 {
    width: 450px;
  }
  .frameButton:hover {
    background-color: var(--color-gray-1000);
  }
  .bodyItIsOurMissionToBu5 {
    font-size: var(--font-size-3xs);
  }
  .frameButton1,
  .frameDiv23 {
    padding-left: var(--padding-20xs);
    padding-right: var(--padding-20xs);
    box-sizing: border-box;
  }
  .frameDiv23 {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
  }
  .policiesDiv {
    width: 1080px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .frameButton1 {
    width: max-content;
  }
  .frameDiv24 {
    /*flex-direction: column;*/
  }
}
@media screen and (max-width: 880px) {
  .frameDiv24 {
    flex-direction: column;
  }
  .frameButton1 {
    width: 100%;
  }
  .frameDiv2 {
    gap: 30;
  }
  .navBarDiv {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .ourProcessDiv1 {
    font-size: var(--font-size-8xl);
  }
  .ourProcessDiv {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-16xs);
    box-sizing: border-box;
  }
  .frameDiv5,
  .frameDiv6 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv8 {
    display: none;
  }
  .div,
  .ourProcessDiv2 {
    flex-direction: column;
  }
  .ourProcessDiv2 {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    box-sizing: border-box;
  }
  .frameDiv10,
  .frameDiv9 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv9 {
    display: flex;
  }
  .frameDiv10 {
    width: 100%;
  }
  .frameDiv12 {
    display: none;
  }
  .frameDiv12,
  .frameDiv19,
  .frameDiv21 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv21 {
    display: none;
  }
  .ourProcessDiv6 {
    flex-direction: column;
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    box-sizing: border-box;
  }
  .bodyItIsOurMissionToBu5 {
    font-size: var(--font-size-xs);
  }
  .frameButton1 {
    width: 100%;
  }
  .frameDiv25,
  .frameDiv26 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv24 {
    flex-direction: column;
    padding-top: var(--padding-7xl);
    padding-bottom: var(--padding-7xl);
    box-sizing: border-box;
  }
  .frameDiv23 {
    padding: var(--padding-17xl) var(--padding-12xs) var(--padding-18xl);
    box-sizing: border-box;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-20xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .policiesDiv,
  .topLinksDiv {
    flex-direction: row;
  }
  .policiesDiv {
    width: 690px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-20xs);
    box-sizing: border-box;
  }
  .frameDiv23 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .initialInquiryDiv {
    font-size: var(--font-size-7xl);
    line-height: 48px;
  }
  .frameDiv6 {
    flex-direction: row;
  }
  .ourProcessDiv2 {
    border-top: 50px;
  }
  .frameDiv10 {
    width: 100%;
  }
  .planDevelopmentImplementat,.initialInquiryDiv {
    font-size: 30px;
    line-height: 48px;
  }
  .frequentlyAskedQuestions {
    font-size: 40px;
  }
  .whatIsTheMinimumAmountOf {
    width: calc(50vw - 100px);
  }
  .frameDiv29, .frameDiv32, .frameDiv28, .frameDiv30 {
    gap: 60px;
  }
  .text12 {
    width: 120px;
  }
  .bodyItIsOurMissionToBu4 {
    font-size: var(--font-size-2xs);
    letter-spacing: 0.18em;
  }
  .frameButton {
    max-width: 75%;
  }
  .ourProcessDiv6 {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .bodyItIsOurMissionToBu5 {
    font-size: var(--font-size-4xs);
  }
  .frameButton1 {
    width: 100%;
  }
  .frameDiv24 {
    padding-top: var(--padding-16xl);
    padding-bottom: var(--padding-12xs);
    box-sizing: border-box;
  }
  .frameDiv23 {
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
  .portfolioStrategiesDiv,
  .whyPSDiv {
    display: none;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
  }
  .privacyPolicyTermsAndCon {
    width: 192px;
  }
  .madeWithByDigitalArtist {
    width: 261px;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .ourProcessDiv {
    padding-top: 180px;
    padding-bottom: 20px;
  }
  .frameDiv5 {
    height: unset;
  }

  .frameDiv9 {
    height: 125px;
  }
  .frameDiv23 {
    padding-bottom: 50px;
  }
  .frameDiv24 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
