@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}

@font-face {
  font-family: "Futura PT";
  src: local("Futura"),
    url("./fonts/futura/futura light bt.ttf") format("truetype");
  font-weight: normal;
}

:root {
  /* fonts */
  --header-tittles: "Futura PT";
  --h1: Lora;

  /* font sizes */
  --font-size-5xs: 12px;
  --font-size-4xs: 13px;
  --font-size-3xs: 14px;
  --font-size-2xs: 15px;
  --font-size-xs: 16px;
  --font-size-sm: 17px;
  --body-text-size: 22px;
  --font-size-lg: 20px;
  --font-size-xl: 22px;
  --small-tittle-size: 25px;
  --header-tittles-size: 30px;
  --font-size-4xl: 35px;
  --font-size-5xl: 38px;
  --mid-tittles-size: 40px;
  --font-size-7xl: 42px;
  --font-size-8xl: 50px;
  --font-size-9xl: 55px;
  --h1-size: 60px;

  /* Colors */
  --beige-10: #faf8f5;
  --neutral-3: #f1f5f9;
  --color-gray-300: #f3f3f3;
  --beige-20: #f5f2ea;
  --light-10: #eeedec;
  --light-60: #cdc9c6;
  --color-gray-600: #8e8373;
  --brown-60: #917e73;
  --color-gray-800: #877a72;
  --color-gray-900: #64748b;
  --color-gray-1000: #777169;
  --color: #75655c;
  --color-gray-1200: #6f625a;
  --color-gray-1300: #574b44;
  --grey-100: #1a364a;
  --brown-100: #251b19;
  --color-gray-1600: #2d2921;
  --color-gray-1700: #2c231e;
  --color-gray-1800: #221f20;
  --color-brown: #1e1e1e;
  --studio-darkmode-allwhite-ffffff: #fff;
  --brown-10: #d4cdc9;
  --brown-80: #75655c;
  --color-tan: #beb3ac;
  --gold-90: #e0ac69;
  --color-orange: #9e6104;
  --white: #fff;

  /* Gaps */
  --gap-17xs: 2px;
  --gap-16xs: 3px;
  --gap-15xs: 6px;
  --gap-14xs: 7px;
  --gap-13xs: 10px;
  --gap-12xs: 11px;
  --gap-11xs: 12px;
  --gap-10xs: 15px;
  --gap-9xs: 16px;
  --gap-8xs: 20px;
  --gap-7xs: 21px;
  --gap-6xs: 22px;
  --gap-5xs: 23px;
  --gap-4xs: 25px;
  --gap-3xs: 26px;
  --gap-2xs: 30px;
  --gap-xs: 34px;
  --gap-sm: 41px;
  --gap-md: 45px;
  --gap-lg: 50px;
  --gap-xl: 60px;
  --gap-2xl: 70px;
  --gap-3xl: 72px;
  --gap-4xl: 74px;
  --gap-5xl: 75px;
  --gap-6xl: 76px;
  --gap-7xl: 80px;
  --gap-8xl: 95px;
  --gap-9xl: 100px;
  --gap-10xl: 101px;
  --gap-11xl: 106px;
  --gap-12xl: 126px;
  --gap-13xl: 135px;
  --gap-14xl: 168px;
  --gap-15xl: 172px;
  --gap-16xl: 196px;

  /* Paddings */
  --padding-21xs: 8px;
  --padding-20xs: 10px;
  --padding-19xs: 15px;
  --padding-18xs: 18px;
  --padding-17xs: 20px;
  --padding-16xs: 21px;
  --padding-15xs: 22px;
  --padding-14xs: 24px;
  --padding-13xs: 25px;
  --padding-12xs: 30px;
  --padding-11xs: 36px;
  --padding-10xs: 40px;
  --padding-9xs: 50px;
  --padding-8xs: 52px;
  --padding-7xs: 58px;
  --padding-6xs: 60px;
  --padding-5xs: 70px;
  --padding-4xs: 75px;
  --padding-3xs: 80px;
  --padding-2xs: 90px;
  --padding-xs: 96px;
  --padding-sm: 100px;
  --padding-md: 103px;
  --padding-lg: 108px;
  --padding-xl: 110px;
  --padding-2xl: 118px;
  --padding-3xl: 121px;
  --padding-4xl: 127px;
  --padding-5xl: 130px;
  --padding-6xl: 148px;
  --padding-7xl: 150px;
  --padding-8xl: 180px;
  --padding-9xl: 181px;
  --padding-10xl: 200px;
  --padding-11xl: 220px;
  --padding-12xl: 250px;
  --padding-13xl: 300px;
  --padding-14xl: 400px;
  --padding-15xl: 430px;
  --padding-16xl: 450px;
  --padding-17xl: 480px;
  --padding-18xl: 500px;
  --padding-19xl: 570px;
  --padding-20xl: 750px;
  --padding-21xl: 800px;

  /* border radiuses */
  --br-xs: 2px;
  --br-sm: 10px;
  --br-md: 30px;
  --br-lg: 50px;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--brown-10) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--brown-10) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--brown-10) !important;
}
