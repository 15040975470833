@keyframes fade-in {
  0% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@keyframes shadow-inset-center {
  0% {
    box-shadow: inset 0 0 0 0 transparent;
  }
  to {
    box-shadow: inset 0 0 14px 0 rgb(0 0 0/50%);
  }
}
.hr_yellow {
  width: 120px;
  height: 2px;
  z-index: 1;
  /* gold/90 */
  background-color: var(--gold-90);
}
.psLogo062Icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
  object-fit: cover;
}
.navbarLogo2 {
  display: none;
}
.frameDiv1 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--white);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-20xs) var(--padding-17xs);
  background-color: var(--gold-90);
  /*width: 160px;*/
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  transition: 0.3s background-color;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.button:hover span {
  color: #fff;
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Div {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  cursor: pointer;
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.navBarDiv {
  align-self: stretch;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35) 31.77%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(180deg, rgba(33, 25, 23, 0.9) 0%, rgba(33, 25, 23, 0) 100%);
  flex-direction: column;
  padding: var(--padding-13xs) var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}
.aSecureStructuredApproach,
.investInSomethingBetter {
  margin: 0;
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.investInSomethingBetter {
  font-size: inherit;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  font-family: inherit;
  opacity: 1;
}
.animate {
  animation: 4s ease 0s 1 normal forwards fade-in;
}
.aSecureStructuredApproach {
  font-size: var(--header-tittles-size);
  line-height: 45px;
  font-weight: 400;
  font-family: var(--header-tittles);
  opacity: 1;
}
.bannerContaierDiv,
.frameDiv4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frameDiv4 {
  gap: var(--gap-13xs);
}
.bannerContaierDiv {
  padding: 296px var(--padding-4xs);
  text-align: center;
  font-size: var(--h1-size);
  color: var(--studio-darkmode-allwhite-ffffff);
  font-family: var(--h1);
  position: relative;
  overflow: hidden;
}
.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*bottom: 0;*/
  /*min-width: 100%;*/
  /*min-height: 100%;*/
  width: 100%;
}
.structure1Icon {
  position: relative;
  width: 22.5px;
  height: 22.25px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.frameDiv7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.disciplineStructureAnalysisH4 {
  margin: 0;
  flex: 1;
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 22.25px;
  color: var(--gold-90);
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  font-size: 25px;
  line-height: 45px;
}
.value1Discipline {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xs);
  opacity: 1;
}
.tittleWeInvestInYourFin {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 125%;
  /* or 75px */
  color: var(--grey-100);
  letter-spacing: 0.02em;
  display: inline-block;
}
.bodyItIsOurMissionToBu,
.buttonP {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
  padding: 30px 0;
}
.buttonP {
  margin: 0;
  font-size: var(--font-size-xs);
  letter-spacing: 0.21em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
.button1,
.frameDiv6,
.textDiv {
  display: flex;
  flex-direction: column;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-20xs);
  background-color: var(--brown-100);
  width: 553px;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  /*max-width: 80%;*/
  opacity: 1;
}
.button1:hover {
  background-color: var(--color-gray-1000);
}
.frameDiv6,
.textDiv {
  align-items: flex-start;
}
.textDiv {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-2xs);
  font-size: var(--mid-tittles-size);
  font-family: var(--h1);
  opacity: 1;
}
.frameDiv6 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.pexelsAndreaPiacquadio37600Icon {
  flex: 1;
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frameDiv8 {
  flex: 1;
  /*height: 548px;*/
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 1;
}
.firstSeccionDiv,
.frameDiv5 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frameDiv5 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-12xl);
}

.firstSeccionDiv {
  background-color: var(--studio-darkmode-allwhite-ffffff);
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-4xs);
  box-sizing: border-box;
  justify-content: center;
  z-index: 1;
}
.structure1Icon1 {
  position: relative;
  width: 23px;
  height: 25px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.experienceQualitySecurit {
  flex: 1;
  position: relative;

  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  font-size: 25px;
  line-height: 45px;
  display: inline-block;
  color: var(--gold-90);
}
.frameDiv10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-10xs);
}
.asYourFiduciaryPartnerWe,
.whyPortfolioStrategies {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.why_two_includes {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 130%;

  letter-spacing: 0.02em;

  margin-top: 35px;
  color: var(--gold-90);
}
.whyPortfolioStrategies {
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 95px;
  line-height: 116%;
  /* or 110px */

  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: 0.02em;
  color: var(--white);
  margin: auto;
}
.asYourFiduciaryPartnerWe {
  opacity: 1;
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 155%;
  /* identical to box height, or 34px */

  display: flex;
  align-items: center;
  /* text-align: center; */
  color: var(--white);
  margin: auto;
}
.frameDiv11,
.frameDiv9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.frameDiv11 {
  align-self: stretch;
  gap: var(--gap-6xs);
  font-size: var(--h1-size);
  color: var(--beige-20);
  font-family: var(--h1);
}
.frameDiv9 {
  flex: 1;
  gap: var(--gap-17xs);
  opacity: 1;
}
.structure1Icon2 {
  position: relative;
  width: 30px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.9;
}
.disciplineDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xs);
}
.timeTestedFundamentalsAndT {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
}
.disciplineCard,
.disciplineText {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.disciplineText {
  align-self: stretch;
  gap: var(--gap-4xs);
}
.disciplineCard {
  border: 1px solid var(--beige-10);
  box-sizing: border-box;
  position: relative;
  /*height: 389px;*/
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-9xl);
  width: 20%;
  opacity: 1;
}
.disciplineCard:hover {
  /*animation: 5s ease-in 0s 1 normal none shadow-inset-center;*/
  /*opacity: 1;*/
}
.structure2Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.9;
}
.structure2Icon2 {
  position: relative;
  width: 30px;
  height: 26px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.9;
}
.aHybridInvestmentApproachT {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
}
.structureText {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-4xs);
}
.analysisText,
.structureCard,
.structureText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.structureCard {
  width: 30%;
  border: 1px solid var(--beige-10);
  /*height: 389px;*/
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-3xl);
  box-sizing: border-box;
  align-items: center;
  opacity: 1;
}
.analysisText {
  /*width: 216.67px;*/
  align-items: flex-start;
  gap: var(--gap-4xs);
}
.analysisCard,
.frameDiv12 {
  display: flex;
  align-items: center;
}
.analysisCard {
  width: 30%;
  border: 1px solid var(--beige-10);
  /*height: 389px;*/
  flex-direction: column;
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-6xl);
  box-sizing: border-box;
  justify-content: flex-start;
  opacity: 1;
}
.frameDiv12 {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xs);
  font-size: var(--small-tittle-size);
  color: var(--beige-20);
  font-family: var(--h1);
  align-items: stretch;
}
.whyPortafolioCards,
.whyPortfolioContents {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.whyPortafolioCards {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.whyPortfolioContents {
  background-color: var(--brown-100);
  flex-direction: column;
  padding: var(--padding-7xl) var(--padding-4xs);
  box-sizing: border-box;
  justify-content: center;
  color: var(--beige-10);
}
.pexelsAndreaPiacquadio37600Icon1 {
  align-self: stretch;
  position: absolute;
  max-width: 683px;
  overflow: hidden;
  height: 1210px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
  padding-right: 90px;
}
.frameDiv14 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 1;
  margin-right: 150px;
}
.frameDiv142 {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  justify-content: flex-start;
  opacity: 1;
  margin-top: 475px;
  margin-right: 150px;
  margin-left: 90px;
}
.structure1Icon3 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.structure1Icon32 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
}
.value1Discipline1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xs);
  opacity: 1;
}
.competitive_list_item,
.we_invest_list_item {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 148%;
  /* or 36px */

  /* Grey/100 */

  color: var(--grey-100);
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.competitive_list_row {
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.ourInvestmentProcess {
  background: var(--brown-100);
  width: 100%;
}

.our_investment_title {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 125%;
  /* or 75px */

  letter-spacing: 0.02em;

  /* Beige/20 */

  color: var(--beige-20);
  margin-top: 15px;
  margin-bottom: 0;
}
.ourInvestmentIcon {
  width: 39px;
  height: 25px;
}
.our_investment_paragraph {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 155%;
  /* or 34px */

  /* Light/60 */

  color: var(--light-60);
}
.our_investment_img {
  max-width: 683px;
  height: 837px;
  width: 100%;
}
.portfolio_care {
  background: var(--white);
  margin: 385px auto;
}
.portfolio_care_p {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 140%;
  /* or 84px */

  letter-spacing: 0.02em;
  color: var(--grey-100);
  max-width: 1482px;
  margin: 0 20px;
}
.our_relationship {
  background: var(--white);
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--gap-8xl);
  align-items: flex-end;
}
.our_relationship_left {
}
.our_relationship_left_img {
  max-width: 835px;
  height: 584px;
  object-fit: cover;
}
.our_relationship_right {
  margin-right: 100px;
}
.our_relationship_right_h4 {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 125%;
  /* or 75px */

  letter-spacing: 0.02em;
  color: var(--grey-100);
  margin: 0;
}
.our_relationship_right_p {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 155%;
}
.we_invest_list_item {
  /* margin-bottom: 0 !important; */
  padding-bottom: 0;
  vertical-align: bottom;
}
.our_approach_btn {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 63px;
  gap: 10px;

  width: max-content;
  min-height: 63px;
  background: var(--gold-90);

  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */
  margin-top: 50px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
}
.tittleWeInvestInYourFin1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  /* H1 */

  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 95px;
  line-height: 116%;
  /* or 110px */

  letter-spacing: 0.02em;
  display: inline-block;
  opacity: 1;
  color: var(--grey-100);
  margin-bottom: 25px;
}
.frameDiv15,
.textDiv1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textDiv1 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-2xs);
  font-size: var(--h1-size);
  font-family: var(--h1);
  margin-bottom: 90px;
}
.frameDiv15 {
  margin-left: 150px;
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.frameDiv131 {
  align-items: flex-start !important;
  margin-top: 275px;
}
.competitiveAdvanatgeSection,
.frameDiv13,
.frameDiv131,
.frameDiv132 {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.frameDiv13 {
  margin-top: 275px;
}
.frameDiv13,
.frameDiv131,
.frameDiv132 {
  flex-direction: row;
  justify-content: flex-start;
  /* gap: var(--gap-12xl); */
}
.competitiveAdvanatgeSection {
  background-color: var(--studio-darkmode-allwhite-ffffff);
  flex-direction: column;
  /* padding: 0 var(--padding-4xs) 0 0; */
  box-sizing: border-box;
  justify-content: center;
}
.div2 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  display: inline-block;
  border: 1px solid #1a364a;
  border-radius: 30px;
  padding: 15px 10px;
}
.div1 {
  border-radius: var(--br-md);
  width: 27px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-19xs) var(--padding-20xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.bodyItIsOurMissionToBu2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
}
.div,
.provenStrategyDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.provenStrategyDiv {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-15xs);
  font-size: var(--mid-tittles-size);
  color: var(--grey-100);
  opacity: 1;
}
.div {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-9xs);
  min-width: 530px;
}
.establishedStabilityDiv,
.personalizedAttentionDiv {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
  display: inline-block;
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 125%;
  /* identical to box height, or 75px */

  letter-spacing: 0.02em;
  color: var(--grey-100);
}
.column1Div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xl);
}
.columnsDiv,
.ourApproachSection {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.columnsDiv {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-7xl);
}
.ourApproachSection {
  background-color: var(--color-gray-300);
  flex-direction: column;
  padding: var(--padding-10xl) 230px;
  box-sizing: border-box;
  justify-content: flex-start;
  font-size: var(--body-text-size);
  color: var(--brown-60);
  font-family: var(--h1);
}
.our_procedure_link {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */

  letter-spacing: 0.08em;
  text-decoration-line: underline;
  text-transform: uppercase;

  /* brown/80 */

  color: var(--brown-80);
}
.informedMeasuredAdaptableDiv {
  flex: 1;
  position: relative;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  opacity: 1;
}
.ourApproachTitle {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 95px;
  line-height: 116%;
  /* or 110px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--grey-100);
  margin: auto;
}
.ourApproachP {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 155%;
  /* identical to box height, or 34px */

  display: flex;
  align-items: center;
  text-align: center;
  color: var(--grey-100);
  margin: auto;
}
.frameDiv16,
.frameDiv17,
.frameDiv18 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameDiv16,
.frameDiv18 {
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv18 {
  gap: var(--gap-6xs);
  font-size: var(--h1-size);
  font-family: var(--h1);
}
.frameDiv16 {
  gap: var(--gap-17xs);
}
.structure2Icon1 {
  position: relative;
  width: 20px;
  height: 16px;
  flex-shrink: 0;
  object-fit: cover;
}
.cutomizedDiv,
.weBelieveThatInvestingShou {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.cutomizedDiv {
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
}
.weBelieveThatInvestingShou {
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  color: var(--grey-100);
  margin-top: 20px;
}
.disciplineCard1 {
  flex: 1;
  height: 389px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-9xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #423730;
  opacity: 1;
}
.disciplineCard1:hover {
  animation: 5s ease-in 0s 1 normal none shadow-inset-center;
  opacity: 1;
}
.structure3Icon1 {
  position: relative;
  width: 19px;
  height: 18px;
  flex-shrink: 0;
  object-fit: cover;
}
.activeDiv,
.withDailySupervisionOfClie {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.activeDiv {
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
}
.withDailySupervisionOfClie {
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  color: var(--grey-100);
  margin-top: 20px;
}
.structureCard1 {
  flex: 1;
  height: 389px;
  flex-direction: column;
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-3xl);
  box-sizing: border-box;
  justify-content: flex-start;
  border: 1px solid #423730;
  opacity: 1;
}
.analysisCard1,
.frameDiv19,
.structureCard1 {
  display: flex;
  align-items: center;
}
.analysisCard1 {
  flex: 1;
  height: 391px;
  flex-direction: column;
  padding: var(--padding-10xs) var(--padding-17xs) var(--padding-6xl);
  box-sizing: border-box;
  justify-content: flex-start;
  border: 1px solid #423730;
  opacity: 1;
}
.frameDiv19 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-md);
  text-align: left;
  font-size: var(--small-tittle-size);
  color: var(--brown-100);
  font-family: var(--h1);
}
.whyPortafolioCards1,
.whyPortfolioContents1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.whyPortafolioCards1 {
  align-items: flex-start;
  gap: var(--gap-lg);
}
.whyPortfolioContents1 {
  background-color: var(--light-10);
  padding: var(--padding-7xl) var(--padding-4xs) var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}
.buttonDiv {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--white);
}
.unstyledButtonDiv {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-21xs) var(--padding-15xs);
  box-sizing: border-box;
  justify-content: center;
}
.button3,
.frameDiv20,
.oURAPPROACHBUTTON,
.unstyledButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameDiv20 {
  flex-direction: row;
  justify-content: space-around !important;
}
.button3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 63px;
  gap: 10px;

  min-width: 504px;
  height: 63px;
  background: var(--gold-90);
  border: none;
}
.button3:hover {
  background-color: #756b5d;
}
.frameDiv20,
.oURAPPROACHBUTTON {
  align-self: stretch;
  justify-content: flex-start;
}
.oURAPPROACHBUTTON {
  background-color: var(--light-10);
  padding: 0 var(--padding-3xl) var(--padding-sm);
  box-sizing: border-box;
  padding-bottom: 300px;
}
.client_onboard_title {
  margin: 155px auto;
  text-align: center;
}
.client_onboard_icon {
  width: 40px;
  height: 40.3px;
}
.client_onboard_title_h4 {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 95px;
  line-height: 116%;
  /* or 110px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--light-10);
  margin: 0;
}
.onboarding_row {
  display: flex;
  flex-direction: row;
  margin: 150px 150px;
  justify-content: space-between;
}
.onboarding_right,
.onboarding_left {
  margin-left: 20px;
  /* align-items: center; */
  justify-content: center;
  /* justify-items: center; */
  display: flex;
  flex-direction: column;
}
.onboarding_indicator {
  width: 27px;
  height: 51px;

  /* gold/90 */

  border: 1px solid var(--gold-90);
  border-radius: 30px;

  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 116%;
  /* identical to box height, or 21px */

  letter-spacing: 0.02em;
  color: var(--gold-90);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: -50px;
  margin-right: 18px;
  margin-top: 12px;
}
.onboarding_title {
  /* Mid Tittles */

  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 125%;
  /* identical to box height, or 75px */

  letter-spacing: 0.02em;
  color: var(--light-10);
  margin: 0;
}
.onboarding_p {
  font-family: "Futura PT";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 155%;
  color: var(--light-10);
  max-width: 750px;
}
.onboarding_indicators {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.onboarding_indicators_icons {
  width: 8px;
  height: 156px;
}
.flex {
  display: flex;
}
.formTitleDiv,
.formTitleSubtext {
  position: relative;
  display: inline-block;
  width: 520px;
}
.formTitleSubtext {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #faf8f5;
}
.formTitleDiv {
  font-size: var(--mid-tittles-size);
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
  font-family: var(--h1);
  color: #f4ead4;
}
.formHeaderDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-14xs);
}
.fillInYourDetailsToGetIn {
  position: relative;
  line-height: 26px;
  font-weight: 900;
  display: inline-block;
  margin: auto;
}
.inputTextField {
  background-color: transparent;
  align-self: stretch;
  color: var(--brown-10) !important;
}
.MuiTextField-root label,
.MuiTextField-root span {
  color: var(--brown-10) !important;
}

.frameDiv22 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-10xs);
}
.buttonDiv1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 7.46px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--studio-darkmode-allwhite-ffffff);
  text-align: center;
  display: inline-block;
  font-weight: bold;
}
.unstyledButtonDiv1 {
  width: 460px;
  overflow: hidden;
  padding: var(--padding-21xs) var(--padding-15xs);
  box-sizing: border-box;
}
.button4,
.contactUsDiv,
.frameDiv21,
.unstyledButtonDiv1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contactUsDivLeftFixture {
  position: absolute;
  left: 0;
  border-top: 104px solid transparent;
  border-bottom: 0px solid transparent;
  bottom: 0px;
  border-left: 290px solid var(--color-gray-1800);
  z-index: 0;
}

.contactUsDivRightFixture {
  position: absolute;
  right: 0;
  border-top: 104px solid transparent;
  border-bottom: 0px solid transparent;
  bottom: 0px;
  border-right: 290px solid var(--color-gray-1800);
  z-index: 0;
}

.button4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--gold-90);
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
}
.button4:hover {
  background-color: #373026;
}
.contactUsDiv,
.frameDiv21 {
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--light-10);
  align-items: baseline;
}
.contactUsDiv {
  align-self: stretch;
  justify-content: center;
  /*background-image: url(../../public/img/contact-us@3x.jpg);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--beige-10);
  width: -moz-fit-content;
  width: 100%;
  align-items: center;
  padding-top: var(--padding-4xl);
  padding-bottom: var(--padding-4xl);
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.groupDiv {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.logoDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.rectangleDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-lg);
  background-color: var(--neutral-3);
}
.shapeIcon,
.vectorIcon {
  position: absolute;
  /*height: 39.86%;*/
  /*width: 18.18%;*/
  top: 30.8%;
  right: 40.14%;
  bottom: 29.34%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon {
  transition: 0.3s transform;
}
.shapeIcon:hover {
  transform: translateY(-3px);
}
.vectorIcon {
  width: 39.83%;
  right: 29.37%;
  left: 30.8%;
}
.groupIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.socialIconsDiv,
.socialIconsDiv1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
}
.socialIconsDiv {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  cursor: pointer;
}
.socialIconsDiv1 {
  display: none;
  justify-content: center;
  gap: var(--gap-4xs);
}
.logoAndSocialMedia {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.homeDiv1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.09em;
  line-height: 148%;
  display: inline-block;
  font-weight: 600;
}
.homeDiv1,
.portfolioStrategiesDiv {
  cursor: pointer;
}
.contactUsDiv1,
.ourCompetitiveAvantage,
.portfolioStrategiesDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  cursor: pointer;
}
.contactUsDiv1 {
  cursor: pointer;
}
.homeDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.aboutUsDiv1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.09em;
  line-height: 148%;
  display: inline-block;
  font-weight: 600;
}
.aboutUsDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  cursor: pointer;
}
.footerLinks,
.frameDiv24 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footerLinks {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameDiv24 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-md);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.frameDiv25 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pORTFOLIOSTRATEGIESAllRigh,
.privacyPolicyTermsAndCon {
  flex: 1;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
}
.privacyPolicyTermsAndCon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
  text-align: center;
}
.privacyPolicyDiv {
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}
.madeWithSpan {
  color: var(--studio-darkmode-allwhite-ffffff);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  flex: 1;
  position: relative;
  text-align: right;
  display: inline-block;
  height: 19px;
}
.frameDiv23,
.policiesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.policiesDiv {
  width: 1225px;
  flex-direction: row;
  gap: var(--gap-16xl);
}
.frameDiv23 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-md);
}
.pORTFOLIOSTRATEGIESAllRigh1 {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon1 {
  line-height: 148%;
}
.madeWithByDigitalArtist1,
.privacyPolicyTermsAndCon1 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  height: 19px;
  flex-shrink: 0;
}
.policiesDiv1 {
  width: 1224.03px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-10xs);
  text-align: center;
}
.footerSectionDiv,
.hOMEPAGEDiv {
  display: flex;
  flex-direction: column;
}
.footerSectionDiv {
  align-self: stretch;
  background-color: var(--color-gray-1800);
  padding: var(--padding-8xs) var(--padding-lg);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  font-size: var(--font-size-5xs);
  color: var(--studio-darkmode-allwhite-ffffff);
}
.hOMEPAGEDiv {
  position: relative;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--grey-100);
  font-family: var(--header-tittles);
}

@media screen and (max-width: 1411px) {
  .frameDiv12,
  .frameDiv9 {
    flex: unset;
    align-self: stretch;
  }
  .whyPortafolioCards,
  .whyPortafolioCards1 {
    flex-direction: column;
  }
}

@media screen and (max-width: 1400px) {
  .oURAPPROACHBUTTON {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1035px) {
  .analysisCard1,
  .disciplineCard1,
  .structureCard1 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv19 {
    flex-direction: column;
  }
  .disciplineCard1,
  .structureCard1,
  .analysisCard1 {
    height: 250px;
  }
}
@media screen and (max-width: 1200px) {
  .button {
    /*background-color: var(--brown-100);*/
  }
  .bannerContaierDiv,
  .firstSeccionDiv {
    padding-left: var(--padding-11xs);
    padding-right: var(--padding-11xs);
    box-sizing: border-box;
  }
  .firstSeccionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    padding-bottom: var(--padding-5xl);
  }
  .whyPortfolioStrategies {
    font-size: var(--font-size-9xl);
  }
  .frameDiv9 {
    flex: unset;
    align-self: stretch;
  }
  .aHybridInvestmentApproachT,
  .timeTestedFundamentalsAndT {
    font-size: var(--font-size-xs);
  }
  .structureCard {
    padding-bottom: var(--padding-10xs);
    box-sizing: border-box;
  }
  .frameDiv12 {
    flex: unset;
    align-self: stretch;
  }
  .whyPortafolioCards {
    flex-direction: column;
  }
  .whyPortfolioContents {
    padding-left: var(--padding-10xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .frameDiv14 {
    width: 600px;
  }
  .frameDiv15 {
    width: 400px;
  }
  .competitiveAdvanatgeSection {
    padding-left: 0;
    padding-right: var(--padding-9xs);
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .weBelieveThatInvestingShou,
  .withDailySupervisionOfClie {
    font-size: var(--font-size-xs);
  }
  .structureCard1 {
    padding-bottom: var(--padding-10xs);
    box-sizing: border-box;
  }
  .whyPortafolioCards1 {
    flex-direction: column;
  }
  .oURAPPROACHBUTTON,
  .whyPortfolioContents1 {
    padding-left: var(--padding-10xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .oURAPPROACHBUTTON {
    height: 40px;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    padding-bottom: var(--padding-7xl);
  }
  .contactUsDiv {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
  .policiesDiv,
  .policiesDiv1 {
    width: 100%;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .div {
    min-width: unset;
  }
  .button1 {
    width: 350px;
  }
  .madeWithByDigitalArtist,
  .privacyPolicyTermsAndCon,
  .pORTFOLIOSTRATEGIESAllRigh {
    white-space: nowrap;
    font-size: 10px;
  }
  .policiesDiv {
    gap: unset;
  }
  .privacyPolicyTermsAndCon {
    justify-content: center;
  }
  .bannerContaierDiv {
    padding-top: 140px;
    padding-bottom: 190px;
  }
  .frameDiv13 {
    gap: var(--gap-7xl);
  }
  .frameDiv5 {
    gap: var(--gap-3xl);
  }
  .pexelsAndreaPiacquadio37600Icon {
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  .frameDiv2 {
    gap: 30px;
  }
  .psLogo06x2Icon {
    display: none;
  }
  .navbarLogo2 {
    display: block;
    height: 70px;
  }
  .frameDiv {
    justify-content: space-around;
  }
  .navBarDiv {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .bannerContaierDiv {
    padding: 90px;
    box-sizing: border-box;
    height: 700px;
  }
  .backgroundVideo {
    height: 100%;
    width: unset;
  }
  .pexelsAndreaPiacquadio37600Icon {
    height: unset;
  }

  .button1 {
    width: 100%;
    column-fill: auto;
    min-width: calc(100vw - 200px);
    margin: auto;
  }
  .frameDiv6,
  .frameDiv8 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv5 {
    flex-direction: column;
  }
  .firstSeccionDiv {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    padding-bottom: var(--padding-sm);
    box-sizing: border-box;
  }
  .whyPortfolioStrategies {
    font-size: var(--h1-size);
  }
  .frameDiv12,
  .frameDiv9 {
    /*flex: unset;*/
    align-self: stretch;
  }
  .whyPortafolioCards {
    flex-direction: column;
  }
  .frameDiv14,
  .frameDiv15 {
    width: 768px;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv15 {
    width: 500px;
    padding-left: var(--padding-9xs);
    padding-top: 0;
    box-sizing: border-box;
  }
  .frameDiv13 {
    flex-direction: column;
  }
  .competitiveAdvanatgeSection {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: var(--padding-sm);
    box-sizing: border-box;
  }
  .column1Div {
    flex: unset;
    align-self: stretch;
  }
  .columnsDiv {
    flex-direction: column;
  }
  .ourApproachSection {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .whyPortafolioCards1 {
    flex-direction: column;
  }
  .button3 {
    width: 450px;
  }
  .oURAPPROACHBUTTON {
    height: 400px;
    padding: var(--padding-xl) var(--padding-12xs) 0;
    box-sizing: border-box;
  }
  .button4 {
    background-color: var(--color-tan);
  }
  .contactUsDiv {
    padding: var(--padding-4xl) var(--padding-12xs);
    box-sizing: border-box;
  }
  .groupDiv,
  .logoDiv {
    align-items: center;
  }
  .socialIconsDiv {
    display: none;
  }
  .socialIconsDiv1 {
    display: flex;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-20xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .footerLinks {
    display: none;
  }
  .madeWithByDigitalArtist,
  .pORTFOLIOSTRATEGIESAllRigh,
  .privacyPolicyTermsAndCon {
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    display: none;
    flex-direction: column;
  }
  .policiesDiv1 {
    display: flex;
    flex-direction: column;
  }
  .footerSectionDiv {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    box-sizing: border-box;
  }
  .frameDiv13 {
    overflow: hidden;
  }
  .frameDiv12 {
    flex-flow: column;
  }
  .disciplineCard,
  .structureCard,
  .analysisCard {
    width: 100%;
    min-height: 230px;
    height: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .investInSomethingBetter {
    font-size: var(--font-size-4xl);
  }
  .aSecureStructuredApproach {
    font-size: var(--font-size-lg);
    line-height: 25px;
  }
  .bannerContaierDiv {
    padding-top: var(--padding-11xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .disciplineStructureAnalysisH4 {
    font-size: var(--body-text-size);
    line-height: 20px;
    height: max-content;
  }
  .tittleWeInvestInYourFin {
    font-size: var(--font-size-4xl);
  }
  .buttonP {
    font-size: var(--font-size-3xs);
  }
  .button1 {
    width: calc(100vw - 80px);
    max-width: 95%;
  }
  .button1:hover {
    background-color: var(--color-gray-600);
  }
  .frameDiv6,
  .frameDiv8 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv5 {
    flex-direction: column;
  }
  .firstSeccionDiv {
    padding-top: var(--padding-sm);
    box-sizing: border-box;
  }
  .frameDiv9 {
    width: 100%;
  }
  .disciplineCard,
  .structureCard {
    flex: unset;
    align-self: stretch;
  }
  .analysisCard {
    width: 100%;
    height: max-content;
  }
  .frameDiv12 {
    flex-direction: column;
  }
  .frameDiv14 {
    width: 100%;
    height: 525px;
    flex: unset;
    align-self: stretch;
  }
  .value1Discipline1 {
    flex-direction: row;
    max-width: 400px;
  }
  .tittleWeInvestInYourFin1 {
    font-size: var(--font-size-4xl);
  }
  .frameDiv15 {
    padding-top: var(--padding-7xl);
    box-sizing: border-box;
    max-width: 400px;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv13 {
    flex-direction: column;
  }
  .competitiveAdvanatgeSection {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .bodyItIsOurMissionToBu2 {
    font-size: var(--font-size-sm);
    width: 100%;
  }
  .establishedStabilityDiv,
  .personalizedAttentionDiv {
    font-size: var(--font-size-5xl);
    line-height: 48px;
    width: 100%;
  }
  .establishedStabilityDiv {
    width: 100%;
  }
  .ourApproachSection {
    padding-left: var(--padding-10xs);
    padding-right: var(--padding-10xs);
    box-sizing: border-box;
  }
  .frameDiv16 {
    width: 100%;
  }
  .analysisCard1,
  .disciplineCard1,
  .structureCard1 {
    flex: unset;
    align-self: stretch;
  }
  .analysisCard1 {
    width: 100%;
  }
  .frameDiv19 {
    flex-direction: column;
  }
  .buttonDiv {
    font-size: var(--font-size-3xs);
  }
  .button3 {
    height: 56px;
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
    max-width: 95%;
    width: 100%;
  }
  .button3:hover {
    background-color: #998e7c;
  }
  .oURAPPROACHBUTTON {
    height: 250px;
    padding-left: var(--padding-12xs);
    padding-top: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    box-sizing: border-box;
  }
  .button4 {
    width: 100%;
  }
  .button4:hover {
    background-color: var(--color-orange);
  }
  .frameDiv21 {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .portfolioStrategiesDiv {
    display: none;
  }
  .aboutUsDiv,
  .homeDiv {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
    flex: unset;
    align-self: stretch;
  }
  .madeWithByDigitalArtist,
  .privacyPolicyTermsAndCon {
    width: 192px;
    flex: unset;
    align-self: stretch;
  }
  .madeWithByDigitalArtist {
    width: 261px;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .pORTFOLIOSTRATEGIESAllRigh1 {
    width: 286px;
  }
  .privacyPolicyTermsAndCon1 {
    width: 192px;
  }
  .madeWithByDigitalArtist1 {
    width: 261px;
  }
  .policiesDiv1 {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .disciplineCard1,
  .structureCard1,
  .analysisCard1 {
    height: max-content;
  }
  .formTitleSubtext,
  .formTitleDiv {
    width: 100%;
  }
  .formContact {
    width: 100%;
  }
  .frameDiv15 {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .div1 {
    width: 20px;
    padding-left: 0px;
  }

  .personalizedAttentionDiv,
  .establishedStabilityDiv {
    font-size: 30px;
  }
}
