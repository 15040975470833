.psLogo062Icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
  object-fit: cover;
}
.frameDiv1 {
  width: 43px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--beige-10);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-20xs) var(--padding-17xs);
  background-color: var(--brown-100);
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Div {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  cursor: pointer;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  align-items: center;
}
.frameDiv {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  width: 1920px;
  flex-direction: column;
  padding: var(--padding-13xs) var(--padding-3xs);
  box-sizing: border-box;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}
.aboutUsH1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;
}
.portfolioStrategiesIs {
  margin-block-start: 0;
  margin-block-end: 0;
}
.ourProprietaryMethodology {
  margin: 0;
}
.bodyItIsOurMissionToBu {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
  font-style: normal;
}

.bodyItIsOurMissionToBu2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  display: inline-block;
  padding: 0 155px;
  font-style: normal;
}
.frameDiv5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  opacity: 1;
}
.yibeiGengUdYbiywGegUnsplasIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 453px;
  flex-shrink: 0;
  object-fit: cover;
}
.aboutUsNavBar,
.frameDiv4,
.frameDiv6 {
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
}
.frameDiv6 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-2xs) 0;
  align-items: flex-start;
  opacity: 1;
}
.aboutUsNavBar,
.frameDiv4 {
  align-self: stretch;
}
.frameDiv4 {
  flex-direction: row;
  padding: 0 var(--padding-7xl);
  align-items: flex-start;
  gap: var(--gap-12xl);
}
.aboutUsNavBar {
  flex-direction: column;
  padding: var(--padding-7xl) 0 0;
  align-items: center;
  background-image: url(../../public/img/about-us--nav-bar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--h1-size);
  font-family: var(--h1);
}
.campaignCreatorsGMsnXqILjp4Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 423px;
  flex-shrink: 0;
  object-fit: cover;
  margin-top: 40px;
}

.campaignCreatorsGMsnXqILjp4Icon2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 423px;
  flex-shrink: 0;
  object-fit: cover;
  bottom: -200px;
}
.frameDiv7 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.structure1Icon {
  position: relative;
  width: 17.5px;
  height: 17.63px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.experienceStrategyInnovationDiv {
  flex: 1;
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  opacity: 0.6;
}
.qualityCODiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  opacity: 1;
}
.companyOverviewDiv {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
  display: inline-block;
  font-style: normal;
}
.frameDiv8,
.tittleAndText {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6xs);
}
.tittleAndText {
  justify-content: center;
  font-size: var(--h1-size);
  color: var(--beige-20);
  font-family: var(--h1);
  /*opacity: 1;*/
}
.frameDiv8 {
  justify-content: flex-start;
}
.rEADMOREDiv {
  position: relative;
  letter-spacing: 0.17em;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
}
.icon {
  position: relative;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameDiv9,
.readMoreDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.readMoreDiv {
  align-self: stretch;
  font-size: var(--font-size-4xs);
  color: var(--studio-darkmode-allwhite-ffffff);
}

.readLessDiv {
  align-self: stretch;
  font-size: var(--font-size-4xs);
  color: var(--studio-darkmode-allwhite-ffffff);
  padding: 0 155px;
}
.cOMPANYOVERVIEWSUBCONTAINER {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 80px 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-xs);
}
.comapnyOverview,
.frameAddress,
.frameAddress2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameAddress {
  flex: 1;
  padding: 0 0 0;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--beige-10);
  font-family: var(--header-tittles);
}

.frameAddress2 {
  flex: 1;
  padding: 0 0 0;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--beige-10);
  font-family: var(--header-tittles);
}
.comapnyOverview {
  align-self: stretch;
  min-height: 572px;
  flex-shrink: 0;
  padding: 0 var(--padding-4xs) var(--padding-7xl) 0;
  background-image: url(../../public/img/comapny-overview@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 70px;
  flex-flow: column;
  opacity: 1;
}
.establishedStableProsperousDiv,
.ourHistoryH1 {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}
.establishedStableProsperousDiv {
  line-height: 45px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  flex-shrink: 0;
  opacity: 0.6;
}
.ourHistoryH1 {
  margin: 0;
  font-size: var(--h1-size);
  letter-spacing: 0.02em;
  line-height: 116%;
  font-family: var(--h1);
  display: inline-block;
}
.frameDiv13 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xs);
  opacity: 1;
}
.bodyItIsOurMissionToBu1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  display: inline-block;
  width: 610px;
  margin: auto;
  opacity: 1;
}
.frameDiv12 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.buttonDiv {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 0.21em;
  line-height: 148%;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  text-align: center;
  display: inline-block;
  color: var(--brown-10);
}
.unstyledButtonDiv {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-21xs) 0;
  box-sizing: border-box;
  justify-content: center;
}
.button1,
.frameDiv10,
.frameDiv11,
.unstyledButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--brown-100);
  width: 490px;
  height: 56px;
  flex-shrink: 0;
  overflow: hidden;
  justify-content: center;
  max-width: 90%;
  opacity: 1;
  transition: 0.2s background-color;
}
.button1:hover {
  background-color: var(--color-gray-1000);
}
.frameDiv10,
.frameDiv11 {
  align-self: stretch;
  justify-content: flex-start;
}
.frameDiv11 {
  gap: var(--gap-lg);
}
.frameDiv10 {
  background-color: var(--color-gray-300);
  padding: var(--padding-md) var(--padding-14xl);
  box-sizing: border-box;
  text-align: center;
}
.structure1Icon1 {
  position: relative;
  width: 17.5px;
  height: 17.25px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.frameDiv19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 1;
}
.commitmentIntegritySimplicitDiv {
  flex: 1;
  position: relative;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  opacity: 1;
}
.ourValuesDiv,
.weAreGuidedByCommonPrinci {
  align-self: stretch;
  position: relative;
  display: inline-block;
}
.ourValuesDiv {
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 500;
}
.weAreGuidedByCommonPrinci {
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--header-tittles);
  width: 350px;
}
.frameDiv17,
.frameDiv20 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv20 {
  justify-content: center;
  gap: var(--gap-6xs);
  font-size: var(--h1-size);
  font-family: var(--h1);
  opacity: 1;
}
.frameDiv17 {
  justify-content: flex-start;
  gap: var(--gap-13xs);
}
.structure1Icon2 {
  position: relative;
  width: 19.5px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.9;
}
.frameDiv24 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.structureDiv {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
}
.component3Icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.responsivenessDiv {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 32px;
  flex-shrink: 0;
}
.structure1Icon3 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.9;
}
.frameDiv22 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.structure2Icon {
  position: relative;
  width: 22.5px;
  height: 22.25px;
  flex-shrink: 0;
  object-fit: cover;
}
.disciplineDiv,
.frameDiv30 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.disciplineDiv {
  gap: var(--gap-8xs);
}
.frameIcon {
  position: relative;
  width: 18px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameDiv29 {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.frameDiv16,
.frameDiv21 {
  display: flex;
  justify-content: flex-start;
}
.frameDiv21 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-3xl);
  font-size: var(--small-tittle-size);
  font-family: var(--h1);
  opacity: 1;
}
.frameDiv16 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xl);
}
.frameDiv33,
.honestyCardDiv {
  align-items: flex-start;
  justify-content: flex-start;
}
.honestyCardDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.frameDiv33 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  gap: var(--gap-7xl);
}
.highRiseBuildingWithGlassIcon {
  flex: 1;
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frameDiv36 {
  align-self: stretch;
  height: 480px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.honestyDiv1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 130%;
  font-weight: 500;
  display: inline-block;
}
.frameDiv37,
.iMGANDCARDS {
  display: flex;
  justify-content: flex-start;
}
.frameDiv37 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-7xl);
}
.iMGANDCARDS {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-sm);
  font-size: var(--small-tittle-size);
  font-family: var(--h1);
  opacity: 1;
}
.frameDiv14,
.frameDiv15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameDiv15 {
  flex: 1;
  gap: var(--gap-2xl);
}
.frameDiv14 {
  align-self: stretch;
  background-color: var(--studio-darkmode-allwhite-ffffff);
  padding: var(--padding-md) var(--padding-7xl);
  box-sizing: border-box;
}
.structure1Icon9 {
  position: relative;
  width: 17px;
  height: 17.25px;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.6;
}
.educatedInspiredFocusedDiv,
.ourTeamDiv {
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.educatedInspiredFocusedDiv {
  flex: 1;
  text-transform: uppercase;
  opacity: 0.6;
}
.ourTeamDiv {
  align-self: stretch;
  letter-spacing: 0.02em;
  line-height: 116%;
}
.frameDiv41,
.inputDiv {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv41 {
  display: flex;
  justify-content: flex-start;
}
.inputDiv {
  display: none;
  justify-content: center;
}
.pAULKIRSHENBAUMDiv {
  position: relative;
  letter-spacing: 0.15em;
  line-height: 148%;
  display: inline-block;
  width: 467.5px;
  cursor: pointer;
}

.memberActive {

  text-decoration: underline;
  font-size: var(--font-size-xl);
}

.zevKatzDiv {
  cursor: pointer;
}
.miriamGeretzDiv,
.zevKatzDiv {
  position: relative;
  letter-spacing: 0.15em;
  line-height: 148%;
  text-transform: uppercase;
  display: inline-block;
  width: 467.5px;
}
.frameDiv42 {
  opacity: 1;
}
.frameDiv42,
.nAMESDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.nAMESDiv {
  align-self: stretch;
  font-size: var(--font-size-3xs);
  color: var(--studio-darkmode-allwhite-ffffff);
}
.brownFrame,
.leftSideDiv {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.leftSideDiv {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-9xl);
}
.brownFrame {
  background-color: var(--color);
  flex-direction: row;
  padding: var(--padding-5xl) var(--padding-sm) var(--padding-5xl)
    var(--padding-7xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.founderActingPresident,
.paulKirshenbaumDiv {
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: inline-block;
}
.founderActingPresident {
  text-transform: uppercase;
  height: 28px;
  flex-shrink: 0;
  opacity: 0.6;
}
.paulKirshenbaumDiv {
  font-size: var(--h1-size);
  letter-spacing: 0.02em;
  line-height: 116%;
  font-family: var(--h1);
}
.nAMEANDPOSITION {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-16xs);
}
.drKirschenbaumFoundedPortf {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.frameDiv44 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-text-size);
}
.rEADMOREDiv1,
.readMoreButton {
  display: flex;
  align-items: center;
}
.rEADMOREDiv1 {
  position: relative;
  font-size: var(--font-size-4xs);
  letter-spacing: 0.17em;
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: var(--header-tittles);
  color: var(--grey-100);
  text-align: center;
  justify-content: center;
  width: 100px;
  height: 11px;
  flex-shrink: 0;
}
.readMoreButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 500px;
  flex-direction: row;
  justify-content: flex-start;
}
.bioDiv {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-8xs);
}
.bioDiv,
.frameDiv40,
.frameDiv43 {
  display: flex;
  align-items: flex-start;
}
.frameDiv43 {
  flex: 1;
  background-color: var(--studio-darkmode-allwhite-ffffff);
  flex-direction: column;
  padding: var(--padding-5xl) var(--padding-sm) 0;
  box-sizing: border-box;
  justify-content: center;
  color: var(--grey-100);
  opacity: 1;
}
.frameDiv40 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--beige-10);
  align-items: stretch;
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.groupDiv {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.logoDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.rectangleDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-lg);
  background-color: var(--neutral-3);
}
.shapeIcon,
.vectorIcon {
  position: absolute;
  height: 39.86%;
  width: 18.18%;
  top: 30.8%;
  right: 40.14%;
  bottom: 29.34%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon {
  width: 39.83%;
  right: 29.37%;
  left: 30.8%;
}
.groupIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.socialIconsDiv,
.socialIconsDiv1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
}
.socialIconsDiv {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.socialIconsDiv1 {
  display: none;
  justify-content: center;
  gap: var(--gap-4xs);
}
.logoAndSocialMedia {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.homeDiv1 {
  letter-spacing: 0.09em;
}
.homeDiv1,
.portfolioStrategiesDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.homeDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  cursor: pointer;
}
.historyDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  cursor: pointer;
}
.aboutUsDiv1,
.ourProcessDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.ourProcessDiv {
  cursor: pointer;
}
.footerLinks,
.frameDiv46 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footerLinks {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameDiv46 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-md);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.frameDiv47 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pORTFOLIOSTRATEGIESAllRigh,
.privacyPolicyTermsAndCon {
  flex: 1;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
}
.privacyPolicyTermsAndCon {
  text-align: center;
  height: 19px;
}
.madeWithSpan {
  color: var(--studio-darkmode-allwhite-ffffff);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  flex: 1;
  position: relative;
  text-align: right;
  display: inline-block;
  height: 19px;
}
.frameDiv45,
.policiesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.policiesDiv {
  width: 1225px;
  flex-direction: row;
  gap: var(--gap-16xl);
}
.frameDiv45 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-md);
}
.pORTFOLIOSTRATEGIESAllRigh1 {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon1 {
  line-height: 148%;
}
.madeWithByDigitalArtist1,
.privacyPolicyTermsAndCon1 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  height: 19px;
  flex-shrink: 0;
}
.policiesDiv1 {
  width: 1224.03px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-10xs);
  text-align: center;
}
.aboutUsDiv,
.footerSectionDiv {
  display: flex;
  flex-direction: column;
}
.footerSectionDiv {
  align-self: stretch;
  background-color: var(--color-gray-1800);
  padding: var(--padding-8xs) var(--padding-lg);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  font-size: var(--font-size-5xs);
  color: var(--studio-darkmode-allwhite-ffffff);
}
.aboutUsDiv {
  position: relative;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--grey-100);
  font-family: var(--header-tittles);
}
@media screen and (max-width: 1400px) {
    .brownFrame {
      width: 40%;
    }
  .leftSideDiv {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .button {
    background-color: var(--brown-100);
  }
  .frameDiv4 {
    padding-left: var(--padding-5xs);
    padding-right: var(--padding-5xs);
    box-sizing: border-box;
  }
  .companyOverviewDiv {
    font-size: var(--font-size-9xl);
  }
  .cOMPANYOVERVIEWSUBCONTAINER {
    padding-top: var(--padding-3xs);
    box-sizing: border-box;
  }
  .comapnyOverview {
    height: 550px;
  }
  .frameDiv10 {
    padding-left: var(--padding-10xl);
    padding-right: var(--padding-10xl);
    box-sizing: border-box;
  }
  .frameDiv29 {
    width: 100%;
  }
  .inputDiv {
    display: none;
  }
  .brownFrame {
    width: 550px;
    padding-left: var(--padding-3xs);
    padding-bottom: var(--padding-2xs);
    box-sizing: border-box;
  }
  .frameDiv43 {
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 278px;
  }
  .policiesDiv,
  .policiesDiv1 {
    width: 1080px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .frameDiv14 {
    padding: 160px 10px;
  }
  .frameDiv21 {
    gap: 40px;
  }

  .brownFrame {
    width: 33vw;
    padding-left: 50px;
    padding-right: 50px;
  }

  .frameDiv43 {
    padding: 40px;
  }
  .leftSideDiv {
    width: 100%;
  }
  .readMoreButton {
    width: unset;
  }
}
@media screen and (max-width: 855px) {
  .frameDiv2, .frameDiv29, .frameDiv37, .frameDiv22 {
    font-size: 20px;
  }
  .memberActive {
    font-size: 15px;
  }
  .aboutUsH1, .ourTeamDiv {
    font-size: 55px;
  }
  .brownFrame, .frameDiv43 {
    width: 50vw;
    padding-left: 40px;
    padding-right: 40px;
  }
  .paulKirshenbaumDiv {
    font-size: 40px;
  }
  .readMoreButton {
    width: unset;
  }
  .founderActingPresident {
    min-height: 28px;
    height: max-content;
  }
  .frameDiv10 {
    padding-left: 0;
    padding-right: 0;
    margin: auto;
    width: 100%;
  }
  .iMGANDCARDS {
    gap:95px;
  }
  .comapnyOverview {
    height: max-content;
  }
  .bodyItIsOurMissionToBu2{
    padding-left: 80px;
    padding-right: 40px;
  }
  .readLessDiv {
    width: max-content;
  }
}
@media screen and (max-width: 768px) {
  .frameDiv2 {
    gap: 30;
  }
  .navBarDiv {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .frameDiv5,
  .frameDiv6 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv5 {
    margin-bottom: 25px;
  }
  .frameDiv6 {
    display: flex;
  }
  .frameDiv4 {
    flex-direction: column;
    padding-left: var(--padding-4xs);
    padding-right: var(--padding-4xs);
    box-sizing: border-box;
  }
  .frameDiv7 {
    display: none;
    flex: unset;
    align-self: stretch;
  }
  .cOMPANYOVERVIEWSUBCONTAINER {
    padding-top: var(--padding-sm);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .comapnyOverview,
  .frameAddress,
  .frameAddress2{
    flex-direction: column;
    box-sizing: border-box;
  }
  .frameAddress ,
  .frameAddress2 {
    padding-top: 0;
    flex: unset;
    align-self: stretch;
  }
  .comapnyOverview {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    /*max-height: 700%;*/
    height: max-content;
  }
  .button1 {
    max-width: 80%;
    margin: auto;
  }
  .frameDiv10 {
    padding-left: var(--padding-sm);
    padding-right: var(--padding-sm);
    box-sizing: border-box;
  }
  .ourValuesDiv {
    font-size: var(--font-size-8xl);
  }
  .responsivenessDiv,
  .structureDiv {
    font-size: 25px;
  }
  .frameDiv21 {
    margin-block: 20px;
  }
  .frameDiv16 {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv33 {
    display: flex;
    gap: 40px;
  }
  .frameDiv37 {
    display: none;
  }
  .iMGANDCARDS {
    flex: unset;
    align-self: stretch;
    padding-left: 40px;
    padding-right: 40px;
  }

  .frameDiv15 {
    flex-direction: column;
    align-self: center;
  }
  .ourTeamDiv {
    font-size: var(--font-size-8xl);
  }
  .frameDiv41,
  .inputDiv {
    padding-top: 0;
    box-sizing: border-box;
  }
  .inputDiv {
    display: flex;
  }
  .frameDiv42 {
    display: none;
  }
  .brownFrame {
    width: 100%;
    padding-left: var(--padding-3xs);
    padding-right: 0;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .paulKirshenbaumDiv {
    font-size: var(--font-size-8xl);
  }
  .frameDiv43 {
    width: 100%;
    min-height: 600px;
    padding: var(--padding-7xl) var(--padding-12xs) var(--padding-5xs) var(--padding-12xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv40 {
    flex-direction: column;
  }
  .groupDiv,
  .logoDiv {
    align-items: center;
  }
  .logoDiv {
    padding-left: var(--padding-12xl);
    box-sizing: border-box;
  }
  .socialIconsDiv {
    display: none;
  }
  .socialIconsDiv1 {
    display: flex;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-20xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .footerLinks {
    display: none;
  }
  .madeWithByDigitalArtist,
  .pORTFOLIOSTRATEGIESAllRigh,
  .privacyPolicyTermsAndCon {
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    display: none;
    flex-direction: column;
  }
  .policiesDiv1 {
    display: flex;
    flex-direction: column;
  }
  .footerSectionDiv {
    padding-left: var(--padding-12xs);
    padding-right: var(--padding-12xs);
    box-sizing: border-box;
  }
  .bodyItIsOurMissionToBu2 {
    padding: 0;
  }
  .readLessDiv {
    padding-left: 0;
  }
  .qualityCODiv {
    margin: auto;
  }
  .frameDiv17 {
    align-items: center;
    text-align: center;
  }
  .weAreGuidedByCommonPrinci {
    margin: auto;
  }
  .structure1Icon2, .structure2Icon, .component3Icon, .frameIcon, .structure1Icon3 {
    height: 25px;
    width: 25px;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .aboutUsH1 {
    font-size: var(--font-size-7xl);
    line-height: 48px;
  }
  .frameDiv6 {
    height: 400px;
    padding-top: 0;
    padding-bottom: var(--padding-18xl);
    box-sizing: border-box;
    max-height: 400px;
  }
  .frameDiv4 {
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .experienceStrategyInnovationDiv {
    font-size: var(--body-text-size);
  }
  .cOMPANYOVERVIEWSUBCONTAINER {
    padding-top: var(--padding-9xs);
    box-sizing: border-box;
  }
  .comapnyOverview {
    min-height: 564px;
    height: max-content;
  }
  .bodyItIsOurMissionToBu2 {
    padding: unset;
  }
  .readLessDiv {
    padding: unset;
  }
  .bodyItIsOurMissionToBu {
    padding-top: 20px;
  }
  .ourHistoryH1 {
    font-size: var(--font-size-7xl);
    line-height: 48px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .buttonDiv {
    font-size: var(--font-size-2xs);
    letter-spacing: 0.18em;
  }

  .establishedStableProsperousDiv, .experienceStrategyInnovationDiv {
    line-height: 29px;
  }

  .button1 {
    max-width: 100%;
    width: 100%;
  }
  .button1:hover {
    background-color: var(--color-orange);
  }
  .frameDiv10 {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
  .commitmentIntegritySimplicitDiv {
    font-size: var(--body-text-size);
    line-height: 30px;
  }
  .ourValuesDiv {
    width: 300px;
  }
  .weAreGuidedByCommonPrinci {
    width: 100%;
  }
  .frameDiv33 {
    display: flex;
    gap: 55px;
  }
  .frameDiv36 {
    width: 100%;
  }
  .frameDiv15 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv14 {
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .inputDiv {
    display: flex;
    width: 70%;
    max-width: 300px;
  }
  .frameDiv42 {
    display: none;
  }
  .brownFrame {
    width: 100%;
    height: 700px;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .bioDiv {
    width: 100%;
  }
  .paulKirshenbaumDiv {
    font-size: 40px;
  }
  .frameDiv43 {
    width: 100%;
    min-height: 900px;
    height: max-content;
    /*padding: var(--padding-18xl) 0 var(--padding-16xl) var(--padding-12xs);*/
    box-sizing: border-box;
  }
  .logoDiv {
    padding-left: var(--padding-9xs);
    box-sizing: border-box;
  }
  .portfolioStrategiesDiv {
    display: none;
  }
  .aboutUsDiv1,
  .homeDiv,
  .ourProcessDiv {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
    flex: unset;
    align-self: stretch;
  }
  .madeWithByDigitalArtist,
  .privacyPolicyTermsAndCon {
    width: 192px;
    flex: unset;
    align-self: stretch;
  }
  .madeWithByDigitalArtist {
    width: 261px;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .pORTFOLIOSTRATEGIESAllRigh1 {
    width: 286px;
  }
  .privacyPolicyTermsAndCon1 {
    width: 192px;
  }
  .selectTeamMobile {
    width: calc(100vw - 80px);
  }
  .policiesDiv1 {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-10xs);
    box-sizing: border-box;
    align-self: self-end;
  }

  .bodyItIsOurMissionToBu1 {
    width: 100%;
  }
  .frameDiv16 {
    padding-left: 0;
    padding-right: 0;
  }

  .responsivenessDiv,
  .structureDiv {
    font-size: 18px;
  }
  .iMGANDCARDS {
    padding-left: 0;
    padding-right: 0;
  }
  .structure1Icon2, .structure2Icon, .component3Icon, .frameIcon, .structure1Icon3 {
    height: 20px;
    width: 20px;
  }

}
@media screen and (max-width: 1018px) {
  .cOMPANYOVERVIEWSUBCONTAINER {
    padding-top: var(--padding-17xs);
    box-sizing: border-box;
  }
}
