.psLogo062Icon {
  position: relative;
  width: 196px;
  height: 68.88px;
  flex-shrink: 0;
  object-fit: cover;
}
.frameDiv1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--body-text);
  color: var(--brown-100);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-24xs) var(--padding-21xs);
  background-color: var(--primary-contrast);
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.navBarDiv {
  align-self: stretch;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.14) 48.96%, transparent),
    linear-gradient(180deg, #211917, rgba(33, 25, 23, 0));
  height: 181px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-17xs) var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
}
.fACTSDiv {
  position: relative;
  letter-spacing: 0.22em;
  line-height: 30px;
  font-weight: 500;
  display: inline-block;
}
.factsTable {
  /*border: 1px solid var(--brown-100);*/
  border-collapse: collapse;
}
.factsTable td {
  border: 1px solid var(--brown-100);
  text-align: left;
}

.factsTable tr td:first-of-type {
  background-color: var(--brown-10);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-10xs) 77px;
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 30px;
}

.factsTable tr td:last-of-type {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #3F515F;
  padding: var(--padding-10xs) 77px;
}

.factsTable tr td.tableFacts {
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 450;
  font-size: 35px;
  line-height: 30px;
  /* identical to box height, or 86% */

  align-items: center;
  text-align: center;
  letter-spacing: 0.22em;

  /* Beige/10 */

  color: #FAF8F5;
}

.factsTable tr td.tableFactsTitle {
  font-family: 'Futura';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 137%;
  /* or 41px */

  letter-spacing: 0.02em;

  color: #423730;
}

.frameDiv7 {
  align-self: stretch;
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
}
.wHATDOESPORTFOLIOSTRATEGIES {
  flex: 1;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 137%;
  font-weight: 500;
  display: inline-block;
}
.frameDiv10,
.frameDiv6,
.frameDiv8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.frameDiv8 {
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-21xs) var(--padding-xs);
  align-items: flex-start;
  text-align: left;
  font-size: var(--header-tittles-size);
  color: var(--brown-100);
  font-family: var(--font-futura);
}
.frameDiv10,
.frameDiv6 {
  align-self: stretch;
}
.frameDiv6 {
  align-items: flex-start;
  gap: var(--gap-0);
  color: var(--beige-10);
}
.frameDiv10 {
  background-color: var(--brown-10);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-10xs) 77px;
  align-items: center;
}
.bodyItIsOurMissionToBu {
  flex: 1;
  position: relative;
  line-height: 25px;
  font-weight: 500;
  display: inline-block;
}
.frameDiv11,
.frameDiv13,
.frameDiv9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.frameDiv11 {
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-17xs) var(--padding-xs);
  align-items: flex-start;
  text-align: left;
  font-size: var(--qualities-size);
  color: var(--grey-100);
}
.frameDiv13,
.frameDiv9 {
  align-self: stretch;
}
.frameDiv9 {
  align-items: flex-start;
  gap: var(--gap-0);
}
.frameDiv13 {
  background-color: var(--brown-10);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-10xs) 64px;
  align-items: center;
}
.theTypesOf {
  margin-block-start: 0;
  margin-block-end: 0;
}
.socialSecurityNumber {
  margin-bottom: 0;
}
.socialSecurityNumberAndInc {
  margin: 0;
  padding-left: 27px;
}
.whenYouAre {
  margin: 0;
}
.bodyItIsOurMissionToBu1 {
  flex: 1;
  position: relative;
  line-height: 25px;
  font-weight: 500;
  display: inline-block;
}
.frameDiv12,
.frameDiv14 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv14 {
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-17xs) var(--padding-xs);
  justify-content: center;
  text-align: left;
  font-size: var(--font-inherit);
  color: var(--grey-100);
  font-family: var(--font-inherit);
}
.frameDiv12 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-0);
}
.howDiv {
  position: relative;
  letter-spacing: 0.22em;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
}
.frameDiv16,
.frameDiv17 {
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameDiv16 {
  align-self: stretch;
  background-color: var(--brown-10);
  padding: var(--padding-11xs) 73px;
}
.frameDiv17 {
  flex: 1;
  padding: var(--padding-12xs) 105px var(--padding-12xs) var(--padding-xs);
  text-align: left;
  font-size: var(--qualities-size);
  color: var(--grey-100);
}
.frameDiv4,
.frameDiv5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.frameDiv5 {
  align-items: center;
  justify-content: center;
  gap: var(--gap-0);
}
.frameDiv4 {
  background-color: var(--light-10);
  /*padding: 154px var(--padding-15xl) var(--padding-5xs);*/
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-4xl);
  color: var(--brown-100);
  max-width: 1424px;
  width: 100%;
  margin: auto;
  padding: 154px 50px;
}
.reasonsWeCanShareYourPers {
  flex: 1;
  position: relative;
  line-height: 33px;
  text-transform: uppercase;
  display: inline-block;
}
.frameDiv21,
.frameDiv22 {
  align-self: stretch;
  flex: 1;
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}
.frameDiv21 {
  padding: var(--padding-17xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.frameDiv22 {
  padding: var(--padding-11xs) var(--padding-17xs);
  align-items: center;
  justify-content: center;
}
.canYouLimitThisSharing {
  flex: 1;
  position: relative;
  line-height: 33px;
  text-transform: uppercase;
  display: inline-block;
}
.frameDiv20,
.frameDiv23 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.frameDiv23 {
  flex: 1;
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-21xs) var(--padding-17xs);
  align-items: center;
  justify-content: center;
}
.frameDiv20 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
  color: var(--beige-10);
}
.suchAsTo {
  font-weight: 500;
}
.forOurEverydayBusinessPurp {
  flex: 1;
  position: relative;
  line-height: 25px;
  display: inline-block;
}
.frameDiv25 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-17xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--qualities-size);
}
.yESDiv {
  flex: 1;
  position: relative;
  line-height: 33px;
  text-transform: uppercase;
  display: inline-block;
}
.frameDiv26 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-17xs);
  align-items: center;
  justify-content: center;
}
.nODiv {
  flex: 1;
  position: relative;
  line-height: 33px;
  text-transform: uppercase;
  display: inline-block;
}
.frameDiv24,
.frameDiv29,
.frameDiv33 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv24 {
  gap: var(--gap-0);
}
.frameDiv29,
.frameDiv33 {
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-17xs);
  text-align: left;
  font-size: var(--qualities-size);
}
.frameDiv33 {
  padding: var(--padding-11xs) var(--padding-17xs);
}
.noDiv {
  flex: 1;
  position: relative;
  line-height: 33px;
  text-transform: uppercase;
  display: inline-block;
}
.frameDiv37,
.frameDiv41,
.frameDiv45 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-17xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--qualities-size);
}
.forNonaffiliatesToMarketTo {
  flex: 1;
  position: relative;
  line-height: 25px;
  display: inline-block;
}
.frameDiv18,
.frameDiv49 {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv49 {
  flex: 1;
  border: 1px solid var(--brown-100);
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-17xs);
  text-align: left;
  font-size: var(--qualities-size);
}
.frameDiv18 {
  background-color: var(--light-10);
  flex-direction: column;
  max-width: 1424px;
  width: 100%;
  margin: auto;
  padding: 54px 50px;
}
.qUESTIONSDiv {
  position: relative;
  letter-spacing: 0.22em;
  line-height: 30px;
  font-weight: 500;
  display: inline-block;
}
.frameDiv54 {
  align-self: stretch;
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-4xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.cALL2015714100Div {
  flex: 1;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 137%;
  font-weight: 500;
  display: inline-block;
}
.frameDiv55 {
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-21xs) var(--padding-xs);
  align-items: center;
  text-align: left;
  font-size: var(--header-tittles-size);
  color: var(--brown-100);
  font-family: var(--font-futura);
}
.frameDiv52,
.frameDiv53,
.frameDiv55 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.frameDiv53 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-0);
}
.frameDiv52 {
  background-color: var(--light-10);
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--font-size-4xl);
  color: var(--beige-10);
  max-width: 1424px;
  width: 100%;
  margin: auto;
  padding: 54px 50px;
}
.whoWeAre {
  flex: 1;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
}
.frameDiv58,
.frameDiv59 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv59 {
  flex: 1;
  background-color: var(--brown-100);
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  padding: var(--padding-11xs) var(--padding-17xs);
}
.frameDiv58 {
  font-size: var(--font-size-4xl);
  color: var(--beige-10);
}
.whoIsProvidingThisNotice {
  flex: 1;
  position: relative;
  line-height: 25px;
  display: inline-block;
}
.frameDiv61,
.frameDiv62 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-9xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv62 {
  align-items: center;
  justify-content: center;
}
.howDoesPSIProtectMyPerson {
  flex: 1;
  position: relative;
  line-height: 25px;
  display: inline-block;
}
.frameDiv70 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-9xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
}
.sharingForAffiliateseveryda {
  margin: 0;
  padding-left: var(--padding-15xs);
}
.frameDiv56,
.frameDiv73 {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
}
.frameDiv73 {
  flex: 1;
  border: 1px solid var(--brown-100);
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-9xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-inherit);
}
.frameDiv56 {
  background-color: var(--light-10);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 1424px;
  width: 100%;
  margin: auto;
  padding: 54px 50px;
}
.b {
  flex: 1;
  position: relative;
  line-height: 25px;
  display: inline-block;
}
.frameDiv91 {
  align-self: stretch;
  flex: 1;
  border: 1px solid var(--brown-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-9xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv90 {
  flex: 1;
}
.frameDiv89,
.frameDiv90 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.groupDiv {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.logoDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.groupIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.socialIconsDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupIcon1 {
  position: relative;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.socialIconsDiv1 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.logoAndSocialMedia {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.homeDiv1 {
  letter-spacing: 0.09em;
}
.homeDiv1,
.ourCompetitiveAvantage,
.portfolioStrategiesDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.homeDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  cursor: pointer;
}
.historyDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  cursor: pointer;
}
.aboutUsDiv,
.ourProcessDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.ourProcessDiv {
  cursor: pointer;
}
.footerLinks,
.frameDiv93 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footerLinks {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.frameDiv93 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-md);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.frameDiv94 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pORTFOLIOSTRATEGIESAllRigh,
.termsAndCondition {
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.pORTFOLIOSTRATEGIESAllRigh {
  flex: 1;
  height: 18px;
}
.termsAndCondition {
  cursor: pointer;
}
.frameDiv95 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
  text-align: center;
}
.madeWithSpan {
  color: var(--primary-contrast);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  flex: 1;
  position: relative;
  text-align: right;
  display: inline-block;
  height: 19px;
}
.frameDiv92,
.policiesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.policiesDiv {
  width: 1225px;
  flex-direction: row;
  gap: var(--gap-17xl);
}
.frameDiv92 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-md);
}
.pORTFOLIOSTRATEGIESAllRigh1 {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon {
  line-height: 148%;
}
.madeWithByDigitalArtist1,
.privacyPolicyTermsAndCon {
  align-self: stretch;
  position: relative;
  display: inline-block;
  height: 19px;
  flex-shrink: 0;
}
.footerSectionDiv,
.policiesDiv1 {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.policiesDiv1 {
  width: 1224.03px;
  display: none;
  gap: var(--gap-10xs);
  text-align: center;
}
.footerSectionDiv {
  align-self: stretch;
  background-color: var(--color-gray-1800);
  display: flex;
  padding: var(--padding-8xs) var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-md);
  text-align: left;
  font-size: var(--font-size-5xs);
  color: var(--primary-contrast);
}
.privacyPolicyDiv {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-0);
  text-align: center;
  font-size: var(--small-tittle-size);
  color: var(--grey-100);
  font-family: var(--body-text);
  background-color: var(--light-10);
}
@media screen and (max-width: 1200px) {
  .button {
    background-color: var(--brown-100);
  }
  .bodyItIsOurMissionToBu1 {
    font-size: var(--qualities-size);
  }
  .frameDiv18,
  .frameDiv4 {
    padding-left: var(--padding-2xs);
    padding-right: var(--padding-2xs);
    box-sizing: border-box;
  }
  .frameDiv55 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv53 {
    flex-direction: column;
  }
  .frameDiv52,
  .frameDiv56 {
    padding-left: var(--padding-2xs);
    padding-right: var(--padding-2xs);
    box-sizing: border-box;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 278px;
  }
  .policiesDiv,
  .policiesDiv1 {
    width: 1080px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .frameDiv2 {
    gap: 30;
  }
  .navBarDiv {
    padding-left: var(--padding-21xs);
    padding-right: var(--padding-21xs);
    box-sizing: border-box;
  }
  .wHATDOESPORTFOLIOSTRATEGIES {
    font-size: var(--body-text-size);
  }
  .frameDiv8 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv6 {
    flex-direction: column;
  }
  .frameDiv11 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv9 {
    flex-direction: column;
  }
  .bodyItIsOurMissionToBu1 {
    font-size: var(--qualities-size);
  }
  .frameDiv14 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv12 {
    flex-direction: column;
  }
  .frameDiv17 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .frameDiv4 {
    padding-left: var(--padding-6xs);
    padding-right: var(--padding-6xs);
    box-sizing: border-box;
  }
  .reasonsWeCanShareYourPers {
    font-size: var(--body-text-size);
    line-height: 25px;
  }
  .frameDiv21 {
    padding-top: var(--padding-22xs);
    padding-bottom: var(--padding-22xs);
    box-sizing: border-box;
  }
  .frameDiv22 {
    padding: var(--padding-22xs) var(--padding-24xs);
    box-sizing: border-box;
  }
  .canYouLimitThisSharing {
    font-size: var(--body-text-size);
    line-height: 25px;
  }
  .frameDiv23 {
    padding: var(--padding-22xs) var(--padding-24xs);
    box-sizing: border-box;
  }
  .forOurEverydayBusinessPurp {
    font-size: var(--font-size-3xs);
  }
  .frameDiv25 {
    padding-top: var(--padding-17xs);
    padding-bottom: var(--padding-17xs);
    box-sizing: border-box;
  }
  .frameDiv26 {
    padding-left: var(--padding-24xs);
    padding-right: var(--padding-24xs);
    box-sizing: border-box;
  }
  .frameDiv33 {
    padding: var(--padding-17xs);
    box-sizing: border-box;
  }
  .frameDiv37 {
    padding-top: var(--padding-24xs);
    padding-bottom: var(--padding-24xs);
    box-sizing: border-box;
  }
  .frameDiv41, .frameDiv37 {
    padding-top: 35px;
    padding-bottom: 35px;
    box-sizing: border-box;
  }
  .frameDiv21 {
    padding-top: 20px;
    padding-bottom: 20px;
   }
  .frameDiv45, .frameDiv41 {
    padding-left: var(--padding-17xs);
    padding-right: var(--padding-17xs);
    box-sizing: border-box;
  }
  .forNonaffiliatesToMarketTo {
    font-size: var(--font-size-xs);
    line-height: 18px;
  }
  .frameDiv18 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    padding-bottom: var(--padding-26xs);
    box-sizing: border-box;
  }
  .frameDiv55 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv53 {
    flex-direction: column;
  }
  .frameDiv52 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    padding-bottom: var(--padding-2xs);
    box-sizing: border-box;
  }
  .frameDiv61,
  .frameDiv62,
  .frameDiv70,
  .frameDiv73 {
    flex: unset;
    align-self: stretch;
  }
  .frameDiv56 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
  }
  .groupDiv,
  .logoDiv {
    align-items: center;
  }
  .logoDiv {
    padding-left: var(--padding-16xl);
    box-sizing: border-box;
  }
  .socialIconsDiv {
    display: none;
  }
  .socialIconsDiv1 {
    display: flex;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-24xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .footerLinks {
    display: none;
  }
  .madeWithByDigitalArtist,
  .pORTFOLIOSTRATEGIESAllRigh {
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    display: none;
    flex-direction: column;
  }
  .policiesDiv1 {
    display: flex;
    flex-direction: column;
  }
  .footerSectionDiv {
    padding-left: var(--padding-16xs);
    padding-right: var(--padding-16xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-21xs);
    padding-right: var(--padding-21xs);
    box-sizing: border-box;
  }
  .fACTSDiv,
  .howDiv,
  .wHATDOESPORTFOLIOSTRATEGIES {
    font-size: var(--font-size-7xl);
    line-height: 48px;
  }
  .reasonsWeCanShareYourPers {
    font-size: var(--font-size-3xs);
    line-height: 25px;
  }
  .canYouLimitThisSharing,
  .forOurEverydayBusinessPurp {
    font-size: var(--font-size-3xs);
    line-height: 24px;
  }
  .forOurEverydayBusinessPurp {
    line-height: 25px;
  }
  .nODiv,
  .noDiv,
  .yESDiv {
    font-size: var(--qualities-size);
    line-height: 48px;
  }
  .noDiv {
    line-height: 25px;
  }
  .forNonaffiliatesToMarketTo {
    font-size: var(--font-size-3xs);
    line-height: 25px;
  }
  .frameDiv49,
  .frameDiv54 {
    padding-left: var(--padding-24xs);
    padding-right: var(--padding-24xs);
    box-sizing: border-box;
  }
  .frameDiv54 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
  }
  .cALL2015714100Div {
    font-size: 18px;
    line-height: 25px;
  }
  .frameDiv55 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
  }
  .whoIsProvidingThisNotice {
    font-size: var(--small-tittle-size);
    line-height: 30px;
  }
  .b {
    font-size: var(--font-size-7xl);
    line-height: 48px;
  }
  .logoDiv {
    padding-left: var(--padding-9xs);
    box-sizing: border-box;
  }
  .portfolioStrategiesDiv {
    display: none;
  }
  .aboutUsDiv,
  .homeDiv,
  .ourProcessDiv {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
    flex: unset;
    align-self: stretch;
  }
  .termsAndCondition {
    width: 192px;
  }
  .madeWithByDigitalArtist {
    width: 261px;
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-13xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .pORTFOLIOSTRATEGIESAllRigh1 {
    width: 286px;
  }
  .privacyPolicyTermsAndCon {
    width: 192px;
  }
  .madeWithByDigitalArtist1 {
    width: 261px;
  }
  .policiesDiv1 {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-13xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .factsTable tr td.PrivacyPolicy {
    padding: 15px;
  }

  .factsTable tr td.tableFacts {
    padding: 15px;
    font-size: 22px;
  }

  .factsTable tr td:first-of-type {
    padding: 15px;
    font-size: 22px;
  }
  .factsTable tr td:last-of-type {
    padding: 15px;
    font-size: 18px;
  }
  .frameDiv41,.frameDiv49 {
    padding: var(--padding-11xs) var(--padding-17xs);
  }
  .whoIsProvidingThisNotice, .howDoesPSIProtectMyPerson {
    font-size: 18px;
  }
}
