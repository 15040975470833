.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 529px;
  height: 655px;
}
.contactUsButton,
.hOMEButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-lg);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: var(--header-tittles);
  color: var(--studio-darkmode-allwhite-ffffff);
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.hOMEButton:hover {
  border-bottom: 1px solid white;
}

.contactUsButton {
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  color: var(--beige-10);
  text-align: left;
}
.frameButton,
.frameDiv1 {
  display: flex;
  align-items: flex-start;
}
.frameButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-20xs) var(--padding-6xs);
  background-color: var(--brown-60);
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.frameButton:hover {
  background-color: var(--color-gray-1000);
}
.frameDiv1 {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-8xs);
}
.rectangleDiv1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-lg);
  background-color: var(--neutral-3);
}
.shapeIcon {
  position: absolute;
  height: 39.86%;
  width: 18.19%;
  top: 30.8%;
  right: 40.14%;
  bottom: 29.34%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.shapeIcon2 {
  position: absolute;
  height: 36px;
  width: 36px;
  top: 0%;
  right: 40.14%;
  bottom: 29.34%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.shapeIcon2 {
  transition: 0.3s transform;
}
.shapeIcon2:hover {
  transform: translateY(-3px);
}
.groupButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 25%;
  top: 0;
  right: 75%;
  bottom: 0;
  left: 0;
}
.linkedin1Icon {
  position: absolute;
  height: 39.86%;
  width: 39.86%;
  top: 28.99%;
  right: 29.34%;
  bottom: 31.15%;
  left: 30.8%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupButton1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 25%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 75%;
}
.vectorIcon {
  position: absolute;
  height: 39.86%;
  width: 39.83%;
  top: 30.8%;
  right: 29.37%;
  bottom: 29.34%;
  left: 30.8%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupButton2 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 25%;
  top: 0;
  right: 37.5%;
  bottom: 0;
  left: 37.5%;
}
.groupDiv {
  position: relative;
  width: 144px;
  height: 36px;
  flex-shrink: 0;
}
.socialMediaDiv {
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameDiv {
  position: absolute;
  top: 90px;
  left: 41px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
}
.groupIcon {
  position: absolute;
  top: 13.5px;
  left: 11px;
  width: 17.11px;
  height: 13px;
}
.hamburgerMenuButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 22px;
  left: 241px;
  width: 39px;
  height: 41px;
  overflow: hidden;
}
.menuPS {
  position: relative;
  border-radius: var(--br-sm);
  background-color: var(--color-gray-1700);
  width: 316px;
  height: 382px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  margin-right: 36px;
  margin-top: 22px;
}
@media screen and (max-width: 768px) {
  .hamburgerMenuButton {
    display: flex;
  }
  .menuPS {
    margin-right: -15px;
  }

}
@media screen and (max-width: 428px) {
  .frameButton {
    width: 100%;
  }
}
