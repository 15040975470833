.psLogo062Icon {
  position: relative;
  width: 196px;
  height: 68.88px;
  flex-shrink: 0;
  object-fit: cover;
}
.frameDiv1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: var(--body-text);
  color: var(--brown-100);
  text-align: center;
  display: inline-block;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-24xs) var(--padding-21xs);
  background-color: var(--primary-contrast);
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.button:hover {
  background-color: var(--color-gray-1200);
}
.ellipseIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.7;
}
.ellipseIcon1 {
  position: relative;
  width: 4px;
  height: 4px;
  flex-shrink: 0;
}
.frameDiv3 {
  position: absolute;
  height: 50%;
  width: 11.11%;
  top: 25%;
  right: 43.75%;
  bottom: 25%;
  left: 45.14%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-16xs);
}
.component2Button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}
.frameDiv,
.frameDiv2,
.navBarDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv2 {
  width: 235px;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.frameDiv,
.navBarDiv {
  align-self: stretch;
  justify-content: space-between;
}
.navBarDiv {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.14) 48.96%, transparent),
    linear-gradient(180deg, #211917, rgba(33, 25, 23, 0));
  height: 181px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-17xs) var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
}
.portfolioStrategiesInc {
  margin-block-start: 0;
  margin-block-end: 0;
}
.httpsadviserinfosecgovfi {
  margin: 0;
}
.bodyItIsOurMissionToBu {
  align-self: stretch;
  position: relative;
  font-size: var(--body-text-size);
  line-height: 148%;
  font-family: var(--body-text);
  display: inline-block;
}
.frameDiv4,
.importantDisclosuresDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv4 {
  gap: var(--gap-2xs);
}
.importantDisclosuresDiv {
  background-color: var(--light-10);
  padding: var(--padding-10xl) var(--padding-7xl) 39px;
  box-sizing: border-box;
}
.termsConditionsOfUse {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
  display: inline-block;
}
.theseTerms {
  line-height: 148%;
}
.accessToWebsite {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: var(--mid-tittles-size);
  font-family: var(--mid-tittles);
}
.policiesGoverningUse {
  letter-spacing: 0.02em;
  line-height: 116%;
  font-weight: 600;
}
.bodyItIsOurMissionToBu1 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  font-size: var(--body-text-size);
  font-family: var(--body-text);
}
.importantDisclosuresDiv2 {
  align-self: stretch;
  background-color: var(--light-10);
  display: flex;
  flex-direction: column;
  padding: 101px var(--padding-7xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.psLogo031Icon,
.psLogo061Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 106px;
  object-fit: cover;
}
.psLogo031Icon {
  top: 28.5px;
  left: 86px;
  width: 134px;
  height: 49px;
}
.groupDiv {
  position: relative;
  width: 220px;
  height: 106px;
  flex-shrink: 0;
}
.logoDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.groupIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.socialIconsDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupIcon1 {
  position: relative;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.socialIconsDiv1 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.logoAndSocialMedia {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.homeDiv1 {
  letter-spacing: 0.09em;
}
.homeDiv1,
.ourCompetitiveAvantage,
.portfolioStrategiesDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.homeDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  cursor: pointer;
}
.historyDiv {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  cursor: pointer;
}
.aboutUsDiv,
.ourProcessDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.ourProcessDiv {
  cursor: pointer;
}
.footerLinks,
.frameDiv7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.footerLinks {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.frameDiv7 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-md);
}
.lineDiv {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--brown-10);
  box-sizing: border-box;
  height: 1px;
  flex-shrink: 0;
}
.frameDiv8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pORTFOLIOSTRATEGIESAllRigh,
.privacyPolicyDiv {
  position: relative;
  line-height: 148%;
  display: inline-block;
}
.pORTFOLIOSTRATEGIESAllRigh {
  flex: 1;
  height: 18px;
}
.privacyPolicyDiv {
  cursor: pointer;
}
.frameDiv9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-0);
  text-align: center;
}
.madeWithSpan {
  color: var(--primary-contrast);
}
.span {
  color: var(--color-gray-900);
}
.span1 {
  letter-spacing: 0.01em;
}
.madeWithByDigitalArtist {
  flex: 1;
  position: relative;
  text-align: right;
  display: inline-block;
  height: 19px;
}
.frameDiv6,
.policiesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.policiesDiv {
  width: 1225px;
  flex-direction: row;
  gap: var(--gap-17xl);
}
.frameDiv6 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-md);
}
.pORTFOLIOSTRATEGIESAllRigh1 {
  align-self: stretch;
  position: relative;
  line-height: 148%;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
}
.privacyPolicyTermsAndCon {
  line-height: 148%;
}
.madeWithByDigitalArtist1,
.privacyPolicyTermsAndCon {
  align-self: stretch;
  position: relative;
  display: inline-block;
  height: 19px;
  flex-shrink: 0;
}
.footerSectionDiv,
.policiesDiv1 {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.policiesDiv1 {
  width: 1224.03px;
  display: none;
  gap: var(--gap-10xs);
  text-align: center;
}
.footerSectionDiv {
  align-self: stretch;
  background-color: var(--color-gray-1800);
  display: flex;
  padding: var(--padding-8xs) var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-md);
  font-size: var(--font-size-5xs);
  color: var(--primary-contrast);
  font-family: var(--body-text);
}
.termsAndConditions {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-0);
  text-align: left;
  font-size: var(--h1-size);
  color: var(--grey-100);
  font-family: var(--mid-tittles);
}
@media screen and (max-width: 1200px) {
  .button {
    background-color: var(--brown-100);
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 278px;
  }
  .policiesDiv,
  .policiesDiv1 {
    width: 1080px;
  }
  .footerSectionDiv {
    padding-left: var(--padding-9xs);
    padding-right: var(--padding-9xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 768px) {
  .frameDiv2 {
    gap: 30;
  }
  .navBarDiv {
    padding-left: var(--padding-21xs);
    padding-right: var(--padding-21xs);
    box-sizing: border-box;
  }
  .importantDisclosuresDiv,
  .importantDisclosuresDiv2 {
    padding-left: var(--padding-2xs);
    padding-right: var(--padding-2xs);
    box-sizing: border-box;
  }
  .groupDiv,
  .logoDiv {
    align-items: center;
  }
  .logoDiv {
    padding-left: var(--padding-16xl);
    box-sizing: border-box;
  }
  .socialIconsDiv {
    display: none;
  }
  .socialIconsDiv1 {
    display: flex;
  }
  .logoAndSocialMedia {
    padding-left: var(--padding-24xs);
    padding-right: 0;
    box-sizing: border-box;
  }
  .footerLinks {
    display: none;
  }
  .madeWithByDigitalArtist,
  .pORTFOLIOSTRATEGIESAllRigh {
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    display: none;
    flex-direction: column;
  }
  .policiesDiv1 {
    display: flex;
    flex-direction: column;
  }
  .footerSectionDiv {
    padding-left: var(--padding-16xs);
    padding-right: var(--padding-16xs);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 428px) {
  .button:hover {
    background-color: var(--color-gray-1200);
  }
  .frameDiv2 {
    gap: 20;
  }
  .navBarDiv {
    width: 100%;
    padding-left: var(--padding-21xs);
    padding-right: var(--padding-21xs);
    box-sizing: border-box;
  }
  .importantDisclosuresDiv {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
  }
  .termsConditionsOfUse {
    font-size: var(--font-size-7xl);
    line-height: 48px;
  }
  .importantDisclosuresDiv2 {
    padding-left: var(--padding-13xs);
    padding-right: var(--padding-13xs);
    box-sizing: border-box;
  }
  .logoDiv {
    padding-left: var(--padding-9xs);
    box-sizing: border-box;
  }
  .portfolioStrategiesDiv {
    display: none;
  }
  .aboutUsDiv,
  .homeDiv,
  .ourProcessDiv {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    display: none;
    flex-direction: column;
    align-self: center;
  }
  .pORTFOLIOSTRATEGIESAllRigh {
    width: 286px;
    flex: unset;
    align-self: stretch;
  }
  .privacyPolicyDiv {
    width: 192px;
  }
  .madeWithByDigitalArtist {
    width: 261px;
    flex: unset;
    align-self: stretch;
  }
  .policiesDiv {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-13xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .pORTFOLIOSTRATEGIESAllRigh1 {
    width: 286px;
  }
  .privacyPolicyTermsAndCon {
    width: 192px;
  }
  .madeWithByDigitalArtist1 {
    width: 261px;
  }
  .policiesDiv1 {
    width: 428px;
    flex-direction: column;
    padding-left: var(--padding-13xs);
    box-sizing: border-box;
    align-self: self-end;
  }
  .httpsadviserinfosecgovfi {
    word-break: break-all;
  }
}
